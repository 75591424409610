import React from 'react';
import { fontSizes, fontWeights } from '../modules/theme';

export default function LoadingAnimation() {
    return (
        <div
            css={{
                width: '100%',
                height: '100vh',
                display: 'grid',
                justifyItems: 'center',
            }}
        >
            <div css={{ width: '100px' }}>
                <img
                    src="/images/Spinner-1s-200px.svg"
                    alt="loading"
                    css={{ maxWidth: '100%', height: 'auto', marginTop: 50 }}
                />
                <p
                    css={{
                        fontWeight: fontWeights.bold,
                        fontSize: fontSizes.h2,
                        textAlign: 'center',
                        marginTop: 0,
                    }}
                >
                    Loading
                </p>
            </div>
        </div>
    );
}

import { uniq } from 'lodash/fp';
const initialState = {
    futureOccupations: [],
    alternateTitleCurrentOccupation: null,
    alternateTitlePastOccupations: [],
    removedSkills: [],
    addedSkills: [],
    removedTools: [],
    addedTools: [],
    onlyShowPositiveGrowth: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'RESET_STATE': {
            return initialState;
        }
        case 'ADD_FUTURE_OCCUPATION': {
            if (
                action.payload ===
                state.alternateTitleCurrentOccupation.ANZSCO_MAPPED_CODE
            ) {
                return state;
            }

            return {
                ...state,
                futureOccupations: uniq(
                    state.futureOccupations.concat(action.payload)
                ),
            };
        }
        case 'REMOVE_FUTURE_OCCUPATIONS': {
            return {
                ...state,
                futureOccupations: state.futureOccupations.filter(
                    code => code !== action.payload
                ),
            };
        }
        case 'RESET_FUTURE_OCCUPATIONS': {
            return { ...state, futureOccupations: [] };
        }
        case 'SET_ALTERNATE_TITLE_CURRENT_OCCUPATION': {
            return {
                ...state,
                alternateTitleCurrentOccupation: action.payload,
            };
        }
        case 'SET_ALTERNATE_TITLE_PREV_OCCUPATION': {
            return {
                ...state,
                alternateTitlePastOccupations: action.payload,
            };
        }
        case 'ADD_ADDED_SKILL': {
            // This means the skill was added to
            const skillIsAddedToRemoveList = state.removedSkills.find(
                x => x.label === action.payload.label
            );

            if (skillIsAddedToRemoveList) {
                // Is skill is added to remove list it menas that by default it was included on the occupation.
                // This means we dont need to add it to addedSkills, but rather just remove it from removedSkills
                return {
                    ...state,
                    removedSkills: state.removedSkills.filter(
                        x => x.label !== action.payload.label
                    ),
                };
            }
            return {
                ...state,
                addedSkills: state.addedSkills.concat(action.payload),
            };
        }
        case 'ADD_REMOVED_SKILL': {
            // This means the skill was added to
            const skillIsAddedToAddList = state.addedSkills.find(
                x => x.label === action.payload.label
            );

            if (skillIsAddedToAddList) {
                // Is skill is added to add list it menas that by default the occupation doesnt include this skill.
                // This means we dont need to add it to removedSkills, but rather just remove it from addedSkills
                return {
                    ...state,
                    addedSkills: state.addedSkills.filter(
                        x => x.label !== action.payload.label
                    ),
                };
            }
            return {
                ...state,
                removedSkills: state.removedSkills.concat(action.payload),
            };
        }
        case 'ADD_REMOVED_TOOL': {
            // This means the tool was added previosly
            const toolIsAddedToAddList = state.addedTools.find(
                x => x.label === action.payload.label
            );

            if (toolIsAddedToAddList) {
                // Is tool is added to add list it menas that by default the occupation doesnt include this Tool.
                // This means we dont need to add it to removedTools, but rather just remove it from addedTools
                return {
                    ...state,
                    addedTools: state.addedTools.filter(
                        x => x.label !== action.payload.label
                    ),
                };
            }
            return {
                ...state,
                removedTools: state.removedTools.concat(action.payload),
            };
        }
        case 'ADD_ADDED_TOOL': {
            // This means the tool was added previosly
            const toolsIsAddedToRemoveList = state.removedTools.find(
                x => x.label === action.payload.label
            );

            if (toolsIsAddedToRemoveList) {
                // Is tool is added to remove list it menas that by default it was included on the occupation.
                // This means we dont need to add it to addedTools, but rather just remove it from removedTools
                return {
                    ...state,
                    removedTools: state.removedTools.filter(
                        x => x.label !== action.payload.label
                    ),
                };
            }
            return {
                ...state,
                addedTools: state.addedTools.concat(action.payload),
            };
        }
        case 'RESET_SKILLS': {
            return { ...state, addedSkills: [], removedSkills: [] };
        }
        case 'SET_POSITIVE_GROWTH_TOGGLE': {
            return { ...state, onlyShowPositiveGrowth: action.payload };
        }

        default: {
            return state;
        }
    }
};

export const setPositiveGrowthRateToggle = payload => ({
    type: 'SET_POSITIVE_GROWTH_TOGGLE',
    payload,
});
export const resetFutureOccupations = () => ({
    type: 'RESET_FUTURE_OCCUPATIONS',
});
export const resetSkills = () => ({ type: 'RESET_SKILLS' });
export const resetState = () => ({ type: 'RESET_STATE' });
export const addAddedTool = tool => ({
    type: 'ADD_ADDED_TOOL',
    payload: tool,
});

export const addRemoveTool = tool => ({
    type: 'ADD_REMOVED_TOOL',
    payload: tool,
});
export const addAddedSkill = skill => ({
    type: 'ADD_ADDED_SKILL',
    payload: skill,
});

export const addRemovedSkill = skill => ({
    type: 'ADD_REMOVED_SKILL',
    payload: skill,
});
export const removeFutureOccupationCode = code => ({
    type: 'REMOVE_FUTURE_OCCUPATIONS',
    payload: code,
});
export const addFutureOccupationCode = code => ({
    type: 'ADD_FUTURE_OCCUPATION',
    payload: code,
});

export const setAlternateTitlePastOccupation = code => ({
    type: 'SET_ALTERNATE_TITLE_PREV_OCCUPATION',
    payload: code,
});
export const setAlternateTitleCurrentOccupation = code => ({
    type: 'SET_ALTERNATE_TITLE_CURRENT_OCCUPATION',
    payload: code,
});

import React from 'react';
import PopupWrapper from '../popup-wrapper';
import OccupationPopup from '../occupation-popup';

export default function Popup({
    hoveredCircle,
    top,
    left,
    onMouseLeave,
    numberOfRelatedSkills,
}) {
    return (
        <PopupWrapper top={top} left={left} onMouseLeave={onMouseLeave}>
            <OccupationPopup
                hoveredCircle={hoveredCircle}
                numberOfRelatedSkills={numberOfRelatedSkills}
            />
        </PopupWrapper>
    );
}

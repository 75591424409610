import React from 'react';
import { colors } from '../../modules/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
import { selectOccupationRelatedToOccupation } from '../../ducks/data';
import { Link } from 'react-router-dom';
import occupation from './occupation';
const similiarityScoreToText = score => {
    if (score >= 0.9) return 'strong match';
    if (score >= 0.8) return 'moderate match';
    return 'weak match';
};

const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '45% 20% 10% 15% ',
    alignItems: 'center',
    padding: '0 12px',
};

const HeaderCol = props => (
    <div
        css={{
            color: colors.blue,
            fontSize: '12px',
            fontWeight: '500',
            letterSpacing: '0.5px',
            lineHeight: '16px',
        }}
    >
        {props.children}
    </div>
);
const Col = props => (
    <div
        css={{
            color: colors.darkBlue,
            fontSize: '14px',
            lineHeight: '26px',
        }}
        {...props}
    />
);
const Row = props => (
    <div
        css={{
            ...rowStyle,
            position: 'relative',
            border: '1px solid #DAE3EF',
            borderRadius: '4px',
            backgroundColor: '#FFFFFF',
            paddingTop: 12,
            paddingBottom: 12,
            marginTop: 15,
        }}
        {...props}
    />
);

function OccupationPopup({ onCloseClick, occupations }) {
    return (
        <div
            onClick={e => e.stopPropagation()}
            css={{
                cursor: 'auto',
                width: 860,
                height: 710,
                overflow: 'auto',
                padding: 30,
                boxSizing: 'border-box',
                border: '1px solid #EDF2F7',
                backgroundColor: '#F8FAFD',
                position: 'relative',
            }}
        >
            <h3
                css={{
                    marignTop: 0,
                    color: colors.darkBlue,
                    fontSize: '20px',
                    fontWeight: '500',
                    lineHeight: '30px',
                }}
            >
                Similar jobs to {occupation.ANZSCO_MAPPED_TITLE}
            </h3>
            <FontAwesomeIcon
                onClick={onCloseClick}
                css={{
                    position: 'absolute',
                    top: 20,
                    right: 20,
                    cursor: 'pointer',
                }}
                icon={faTimes}
            />

            <div>
                <div css={rowStyle}>
                    <HeaderCol>JOB</HeaderCol>
                    <HeaderCol>SIMILARITY</HeaderCol>
                    <HeaderCol>WAGE</HeaderCol>
                    <HeaderCol>GROWTH BY 2023</HeaderCol>
                    <div />
                </div>
                {occupations.map((occupation, i) => {
                    return (
                        <Row
                            css={{
                                marginTop: i === 0 && 5,
                            }}
                            key={occupation.id}
                        >
                            <Col>{occupation.label}</Col>
                            <Col>
                                {' '}
                                {(
                                    occupation.similarityScore * 100
                                ).toLocaleString(undefined, {
                                    minimumFractionDigits: 1,
                                    maximumFractionDigits: 1,
                                })}
                                %{' '}
                                {similiarityScoreToText(
                                    occupation.similarityScore
                                )}
                            </Col>
                            <Col>
                                $
                                {occupation.yearlyWage.toLocaleString({
                                    currency: 'AUD',
                                })}
                            </Col>
                            <Col>
                                {occupation.growthRate > 0 ? '+' : '-'}
                                {Math.abs(occupation.growthRate)}%
                            </Col>
                            <Link
                                onClick={onCloseClick}
                                to={`/occupations/${occupation.id}`}
                                css={{
                                    background: colors.darkBlue,
                                    width: 50,
                                    color: 'white',
                                    position: 'absolute',
                                    right: '0',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    borderRadius: '0 4px  4px 0',
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowRight} />
                            </Link>
                        </Row>
                    );
                })}
            </div>
        </div>
    );
}

export default connect((state, props) => ({
    occupations: selectOccupationRelatedToOccupation(
        state,
        props.match.params.occupationCode
    ),
}))(OccupationPopup);

import React from 'react';
import { connect } from 'react-redux';
import {
    flatMap,
    groupBy,
    maxBy,
    flow,
    filter,
    map,
    keyBy,
    orderBy,
} from 'lodash/fp';
import { BlueHeading } from '../../component/ui';
import { colors } from '../../modules/theme';
import CoreSkillBarChart from '../../component/core-skill-bar-chart';

function CoreSkills({
    transferableSkills,
    missingSkills,
    occupation,
    coreSkillsByOccupationCode,
    currentOccupation,
}) {
    return (
        <div
            css={{
                padding: 30,
                width: 370,
                flexShrink: 0,
                border: '1px solid #EDF2F7',
                borderRadius: '4px',
            }}
        >
            <h3 css={{ marginTop: 0 }}>Core competencies</h3>
            <p>
                Set of non-technical tasks, knowledge and understandings that
                underpin successful participation in work.
            </p>
            <BlueHeading>
                Transferable core competencies{' '}
                <span>{transferableSkills.length}</span>
            </BlueHeading>

            {transferableSkills.length === 0 ? (
                <p>No transferable tasks</p>
            ) : (
                <ul css={{ background: colors.whiteBlue, padding: 16 }}>
                    {transferableSkills.map((skill, i) => {
                        return (
                            <li key={i}>
                                <CoreSkillBarChart
                                    showOccupationLabels={true}
                                    skill={skill}
                                    occupation={occupation}
                                    barColor={colors.blue}
                                    coreSkillsByOccupationCode={
                                        coreSkillsByOccupationCode
                                    }
                                />
                            </li>
                        );
                    })}
                </ul>
            )}
            <BlueHeading>
                Core competencies gap <span>{missingSkills.length}</span>
            </BlueHeading>
            {missingSkills.length === 0 ? (
                <p>No skill gap</p>
            ) : (
                <ul css={{ background: colors.whiteBlue, padding: 16 }}>
                    {missingSkills.map((skill, i) => {
                        return (
                            <li key={i}>
                                <CoreSkillBarChart
                                    skill={skill}
                                    occupation={occupation}
                                    coreSkillsByOccupationCode={
                                        coreSkillsByOccupationCode
                                    }
                                    currentOccupation={currentOccupation}
                                />
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
}
export default connect((state, props) => {
    const occupationsByCode = state.data.occupationsByCode;

    const coreSkillsByOccupationCode = state.data.coreSkillsByOccupationCode;
    const { ANZSCO_MAPPED_CODE } =
        state.ui.alternateTitleCurrentOccupation || {};
    const previousOccupations = state.ui.alternateTitlePastOccupations || [];

    const occupation = occupationsByCode[props.match.params.occupationCode];

    const coreSkillsForPickedOccupation =
        coreSkillsByOccupationCode[props.match.params.occupationCode];
    const codesForCurrentAndPastExperience = [ANZSCO_MAPPED_CODE].concat(
        previousOccupations.map(x => x.ANZSCO_MAPPED_CODE)
    );
    // This functions takes the skills for the current job AND all previous jobs
    // Then we group by the skill name.
    // If we have duplicates we take the skill with the highest imporance
    const existingCoreSkillsByName = flow(
        filter(Boolean),
        flatMap(code => coreSkillsByOccupationCode[code]),
        groupBy('SKILL_NAME'),
        Object.entries,
        map(([key, skills]) => {
            const currentSkill = skills.find(
                skill => skill.id === ANZSCO_MAPPED_CODE
            );
            // If we have a current skill use that, otherwise grab the skill with the most hours/week
            if (currentSkill) {
                return currentSkill;
            }
            return maxBy('IMPORTANCE', skills);
        }),
        keyBy('SKILL_NAME')
    )(codesForCurrentAndPastExperience);

    const {
        transferableSkills,
        missingSkills,
    } = coreSkillsForPickedOccupation.reduce(
        (acc, skill) => {
            const exsitingCoreSkill =
                existingCoreSkillsByName[skill.SKILL_NAME];
            if (exsitingCoreSkill) {
                acc.transferableSkills.push(exsitingCoreSkill);
            } else {
                acc.missingSkills.push(skill);
            }
            return acc;
        },
        { transferableSkills: [], missingSkills: [] }
    );

    return {
        transferableSkills: orderBy('IMPORTANCE', 'desc', transferableSkills),
        missingSkills: orderBy('IMPORTANCE', 'desc', missingSkills),
        occupation,
        coreSkillsByOccupationCode,
        currentOccupation:
            occupationsByCode[
                state.ui.alternateTitleCurrentOccupation.ANZSCO_MAPPED_CODE
            ],
    };
})(CoreSkills);

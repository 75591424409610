import React from 'react';
import { colors } from '../modules/theme';

// For triangle
const squareWidth = 10;
const squareHeight = 10;
const triangleHeight = Math.sqrt(10 ^ (2 * 10) ^ 2);
const borderColor = colors.popupBorderBlue;

export default function PopupWrapper({ top, left, onMouseLeave, children }) {
    return (
        <div
            css={{
                position: 'absolute',
                top,
                left,
                zIndex: 50,
                minWidth: '210px',
                transform: `translate(-50%, ${triangleHeight}px)`,
            }}
            onMouseLeave={onMouseLeave}
        >
            <div
                className="invisible-helper-box"
                css={{
                    position: 'absolute',
                    left: '50%',
                    width: '30px',
                    height: 10,
                    backgroundColor: 'transparent',
                    transform: `translate(-50%, -10px)`,
                }}
            />
            <div
                className="popup-body"
                css={{
                    background: colors.whiteBlue,
                    border: '1px solid ' + borderColor,
                    borderRadius: '3px',
                    padding: 7.5,
                    maxWidth: 300,
                }}
            >
                <div
                    className="popup-triangle-container"
                    css={{
                        zIndex: '100',
                        width: '100%',
                        transform: `translateY(-13px)`,
                    }}
                >
                    <div
                        className="popup-top-triangle"
                        css={{
                            width: squareWidth,
                            height: squareHeight,
                            backgroundColor: colors.whiteBlue,
                            borderLeft: '1px solid ' + borderColor,
                            borderTop: '1px solid ' + borderColor,
                            transform: `rotate(45deg)`,
                            margin: 'auto',
                        }}
                    />
                </div>
                {children}
            </div>
        </div>
    );
}

import React from 'react';
import { PopupHeader, PopupParagraph } from './ui';

const Row = props => (
    <div
        css={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 12,
        }}
    >
        {props.children}
    </div>
);
const ValueSpan = props => {
    return <span css={{ textAlign: 'left', width: 40 }}>{props.children}</span>;
};

const OccupationPopup = ({ hoveredCircle, numberOfRelatedSkills }) => {
    return (
        <>
            <PopupHeader
                icon={
                    <img
                        src="/images/transferable-skill-pathway.svg"
                        alt="transferable skill"
                    />
                }
                headline={`${numberOfRelatedSkills} ${
                    numberOfRelatedSkills === 1
                        ? 'Transferable skill'
                        : 'Transferable skills'
                }`}
            />
            <PopupParagraph css={{ fontWeight: 600, marginBottom: 7.5 }}>
                {hoveredCircle.ANZSCO_MAPPED_TITLE}
            </PopupParagraph>
            <Row>
                <span>Job similarity</span>
                <ValueSpan>
                    {(hoveredCircle.similarityScore * 100).toLocaleString(
                        undefined,
                        {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                        }
                    )}
                    %
                </ValueSpan>
            </Row>
            <Row>
                <span>Growth Rate by 2023</span>
                <ValueSpan>
                    {hoveredCircle.growthRate.toLocaleString()}%{' '}
                </ValueSpan>
            </Row>
            <Row>
                <span>Wage</span>
                <ValueSpan>
                    $
                    {hoveredCircle.yearlyWage.toLocaleString({
                        currency: 'AUD',
                    })}
                </ValueSpan>
            </Row>
        </>
    );
};

export default OccupationPopup;

const darkBlue = 'rgb(4,24,54)';
const blue = 'rgb(12,40,82)';
const lightBlue = 'rgb(82,111,153)';
const veryLightBlue = 'rgb(220, 226, 235)';
const popupBorderBlue = 'rgb(186,197,214)';
const dottedBlue = 'rgb(176,192,216)';
export const colors = {
    darkBlue,
    blue,
    lightBlue,
    veryLightBlue,
    whiteBlue: '#EDF2F7',
    popupBorderBlue,
    dottedBlue,
};

export const fontWeights = {
    bold: '600',
};

export const fontSizes = {
    small: '0.9em',
    extraSmall: '0.8em',
    h2: '1.5em',
};

import React from 'react';
import { words } from 'lodash/fp';

const splitText = text => {
    const splitIntoWords = words(text);
    return [
        splitIntoWords.slice(0, splitIntoWords.length / 2 + 1).join(' '),
        splitIntoWords.slice(splitIntoWords.length / 2 + 1).join(' '),
    ];
};
export default function CurrentOccupationTitle({ text, length = 30, ...rest }) {
    const textToRender = text.length > length ? splitText(text) : [text];
    return textToRender.map((x, i) => {
        return (
            <text key={i} {...rest} y={rest.y + i * 12}>
                {x}
            </text>
        );
    });
}

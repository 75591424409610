import React from 'react';
import { flow, groupBy } from 'lodash/fp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TechnologyToolsList = ({ tools, icon, onClick }) => {
    const data = flow(
        groupBy('TECHNOLOGY_FAMILY'),
        Object.entries
    )(tools);

    return (
        <ul>
            {data.map(([group, tools]) => {
                return (
                    <li key={group} css={{ marginTop: 10 }}>
                        <span
                            css={{
                                fontSize: 12,
                            }}
                        >
                            {group}
                        </span>
                        <ul css={{ marginTop: 5 }}>
                            {tools.map(tool => {
                                return (
                                    <li
                                        key={tool.label}
                                        css={{
                                            fontSize: onClick ? 14 : 12,
                                            height: 40,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            padding: '0 10px',
                                            border:
                                                onClick && '1px solid #DAE3EF',
                                            marginTop: 5,
                                        }}
                                    >
                                        {!onClick && '•       '}
                                        {tool.TECHNOLOGY_TOOL}
                                        {onClick && (
                                            <FontAwesomeIcon
                                                css={{ cursor: 'pointer' }}
                                                onClick={e => onClick(tool)}
                                                icon={icon}
                                            />
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            })}
        </ul>
    );
};
export default TechnologyToolsList;

import React from 'react';
import { BlueHeading } from '../../component/ui';
import { connect } from 'react-redux';
import {
    keyBy,
    groupBy,
    flow,
    concat,
    filter,
    flatMap,
    map,
    maxBy,
    getOr,
} from 'lodash/fp';
import { faTimes, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { addRemoveTool, addAddedTool } from '../../ducks/ui';
import TechnologyToolsList from '../../component/technology-tools-list';
import { colors } from '../../modules/theme';

function TechnologyTools({
    currentOccupation,
    occupation,
    transferableTools,
    missingTools,
    addAddedTool,
    addRemoveTool,
}) {
    return (
        <div
            css={{
                border: `1px solid #EDF2F7`,
                padding: '10px 30px',
                marginLeft: '30px',
                marginTop: 30,
            }}
        >
            <h3>Technology Tools</h3>
            <p>
                Technology tools are the skills and literacies needed to use a
                particular software for this job. Technology tools acquired from
                your current or previous experience are used to determine if you
                have transferrable skills or a skills gap. You can add or remove
                skills from either the transferrable skills or skills gap lists.
                Remove a skill from one list will add it to the other list.
            </p>
            <div
                css={{
                    display: 'grid',
                    gridTemplateColumns: '45% 45%',
                    gridColumnGap: '10%',
                }}
            >
                <div>
                    <BlueHeading>
                        Transferable Technology Tools
                        <span>{transferableTools.length}</span>
                    </BlueHeading>
                    <p
                        css={{
                            height: 60,
                            color: colors.lightBlue,
                        }}
                    >
                        Knowledge acquired from{' '}
                        <span css={{ fontWeight: 600 }}>
                            {currentOccupation.ANZSCO_MAPPED_TITLE}
                        </span>{' '}
                        and past experience that you can apply towards{' '}
                        <span css={{ fontWeight: 600 }}>
                            {occupation.label}
                        </span>
                    </p>
                    <TechnologyToolsList
                        onClick={tool => addRemoveTool(tool)}
                        tools={transferableTools}
                        icon={faTimes}
                    />
                </div>
                <div>
                    <BlueHeading>
                        Technology tools gap
                        <span>{missingTools.length}</span>
                    </BlueHeading>
                    <p
                        css={{
                            color: colors.lightBlue,
                            height: 60,
                        }}
                    >
                        Knowledge of tools you need to gain for this job
                    </p>
                    <TechnologyToolsList
                        onClick={tool => addAddedTool(tool)}
                        tools={missingTools}
                        icon={faPlus}
                    />
                </div>
            </div>
        </div>
    );
}

export default connect(
    (state, props) => {
        const occupationsByCode = state.data.occupationsByCode;
        const { occupationCode } = props.match.params;
        const { ANZSCO_MAPPED_CODE } =
            state.ui.alternateTitleCurrentOccupation || {};
        const previousOccupations =
            state.ui.alternateTitlePastOccupations || [];

        const technologyToolsByOccupationCode =
            state.data.technologyToolsByOccupationCode;
        const pickedOccupationTools = getOr(
            [],
            occupationCode,
            technologyToolsByOccupationCode
        );
        const removedToolsByLabel = keyBy('label', state.ui.removedTools);

        const codes = [ANZSCO_MAPPED_CODE].concat(
            previousOccupations.map(x => x.ANZSCO_MAPPED_CODE)
        );

        const currentToolsByLabel = flow(
            filter(Boolean),
            flatMap(code => getOr([], code, technologyToolsByOccupationCode)),
            filter(tool => !removedToolsByLabel[tool.label]),
            concat(state.ui.addedTools),
            groupBy('label'),
            Object.values,
            map(tools => {
                const currentTool = tools.find(
                    tool => tool.ANZSCO_MAPPED_CODE === ANZSCO_MAPPED_CODE
                );
                // If we have a current tool use that, otherwise grab the tool with the most intensity
                if (currentTool) {
                    return currentTool;
                }
                return maxBy('INTENSITY', tools);
            }),
            keyBy('TECHNOLOGY_TOOL')
        )(codes);

        const {
            transferableTools,
            missingTools,
        } = pickedOccupationTools.reduce(
            (acc, tool) => {
                if (currentToolsByLabel[tool.TECHNOLOGY_TOOL]) {
                    acc.transferableTools.push(tool);
                } else {
                    acc.missingTools.push(tool);
                }
                return acc;
            },
            { transferableTools: [], missingTools: [] }
        );

        return {
            occupation: occupationsByCode[occupationCode],
            transferableTools,
            missingTools,
            currentOccupation: state.ui.alternateTitleCurrentOccupation,
        };
    },
    { addAddedTool, addRemoveTool }
)(TechnologyTools);

import React from 'react';
import { PopupHeader, Circle, PopupParagraph } from '../ui';
import { colors } from '../../modules/theme';
import PopupWrapper from '../popup-wrapper';
import OccupationPopup from '../occupation-popup';

const SkillPopup = ({ hoveredCircle }) => {
    return (
        <div>
            <PopupHeader
                headline="Existing Skill"
                icon={
                    <Circle
                        css={{
                            backgroundColor: 'white',
                            border: `1px solid ${colors.blue}`,
                        }}
                    />
                }
            />

            <PopupParagraph>{hoveredCircle.label}</PopupParagraph>
        </div>
    );
};

export default function Popup({
    hoveredCircle,
    top,
    left,
    onMouseLeave = () => {},
    numberOfRelatedSkills,
}) {
    return (
        <PopupWrapper top={top} left={left} onMouseLeave={onMouseLeave}>
            {hoveredCircle.type === 'skill' ? (
                <SkillPopup hoveredCircle={hoveredCircle} />
            ) : (
                <OccupationPopup
                    hoveredCircle={hoveredCircle}
                    numberOfRelatedSkills={numberOfRelatedSkills}
                />
            )}
        </PopupWrapper>
    );
}

import { useEffect } from 'react';
import { colors } from '../../modules/theme';
import { select } from 'd3-selection';
import { get } from 'lodash/fp';
export default function useArcs(arcsRef, occupations, hoveredCircle) {
    useEffect(() => {
        const arcsContainer = select(arcsRef.current);
        let arcsSelection = arcsContainer.selectAll('path').data(occupations);
        arcsSelection.exit().remove();
        arcsSelection = arcsSelection
            .enter()
            .append('path')
            .merge(arcsSelection)
            .attr('stroke', colors.lightBlue)
            .attr('opacity', (d, i, data) => {
                if (!hoveredCircle) return 0.3;
                const destId = get('id', occupations[i + 1]);
                if (
                    hoveredCircle.forOccupations.includes(d.id) &&
                    hoveredCircle.forOccupations.includes(destId)
                ) {
                    return 0.3;
                }
                return 0.1;
            })
            .attr('fill', 'none')
            .attr('stroke-width', 40)
            .attr('class', 'arc')
            .attr('d', (d, i) => {
                const dest = occupations[i + 1];
                if (!dest) return null;
                return `M ${d.x} ${d.y} A ${dest.x - d.x} 256 0 0 1 ${dest.x} ${
                    dest.y
                }`;
            });
    }, [arcsRef, hoveredCircle, occupations]);
}

import React from 'react';
import { scaleLinear } from 'd3-scale';
import { colors } from '../modules/theme';

const scale = scaleLinear()
    .range([0, 100])
    .domain([0, 5]);

const dottedBackground = `repeating-linear-gradient(
    0deg,
    ${colors.dottedBlue},
    ${colors.dottedBlue} 2px,
   transparent 2px,
   transparent 4px
  )`;

const CurrentOccupationBarChart = ({
    coreSkillsByOccupationCode,
    occupation,
    skill,
}) => {
    const skillForOccupation = coreSkillsByOccupationCode[occupation.id].find(
        s => s.SKILL_NAME === skill.SKILL_NAME
    );
    const optimalRight = 100 - scale(skillForOccupation.IMPORTANCE);
    const tooMuchRight = optimalRight - 40 / 2 < 0;
    const tooMuchLeft = optimalRight + 40 > 100;
    const getRightPos = () => {
        if (tooMuchRight) return 0;
        if (tooMuchLeft) return 60;
        return optimalRight;
    };
    const getTransform = () => {
        if (tooMuchRight) return 0;
        if (tooMuchLeft) return 0;
        return 50;
    };
    return (
        <>
            <div
                css={{
                    height: 30,
                    background: dottedBackground,
                    width: 2,
                    position: 'absolute',
                    top: 0,
                    left: scale(skillForOccupation.IMPORTANCE) + '%',
                }}
            />
            <div
                css={{
                    position: 'absolute',
                    top: 30,
                    right: getRightPos() + '%',
                    transform: `translateX(${getTransform()}%)`,
                    width: '40%',
                    borderTop: '2px solid ' + colors.lightBlue,
                    textAlign: 'center',
                    color: colors.blue,
                    fontSize: 12,
                    fontStyle: 'italic',
                }}
            >
                {occupation.label}
            </div>
        </>
    );
};

const CoreSkillBarChart = ({
    skill,
    occupation,
    coreSkillsByOccupationCode,
    showOccupationLabels,
    barColor,
}) => {
    return (
        <div css={{ color: colors.darkBlue }}>
            <span
                css={{
                    fontSize: 13,
                    display: 'block',
                    fontWeight: 'bold',
                    marginBottom: 5,
                }}
            >
                {skill.SKILL_NAME}
            </span>
            <span
                css={{
                    fontStyle: 'italic',
                    fontSize: 12,
                    marginBottom: 5,
                    display: 'block',
                }}
            >
                {showOccupationLabels && skill.ANZSCO_MAPPED_TITLE}
            </span>
            <div css={{ position: 'relative', height: 60 }}>
                <div
                    css={{
                        background: 'white',
                        borderRadius: 20,
                        height: 12,
                        position: 'relative',
                    }}
                >
                    <div
                        css={{
                            background: barColor,
                            borderRadius: '20px',
                            height: '100%',
                            width: scale(skill.IMPORTANCE) + '%',
                        }}
                    />
                    {[20, 40, 60, 80].map(left => {
                        return (
                            <span
                                key={left}
                                css={{
                                    height: '100%',
                                    background: dottedBackground,
                                    width: 2,
                                    position: 'absolute',
                                    top: 0,
                                    left: left + '%',
                                }}
                            />
                        );
                    })}
                </div>
                {showOccupationLabels && (
                    <CurrentOccupationBarChart
                        barColor={colors.blue}
                        coreSkillsByOccupationCode={coreSkillsByOccupationCode}
                        occupation={occupation}
                        skill={skill}
                    />
                )}
            </div>
        </div>
    );
};
export default CoreSkillBarChart;

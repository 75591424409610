import { scaleLinear } from 'd3-scale';
import { colors } from './theme';
export const scale = scaleLinear().range([7, 42]);

export const typeToCircleStyle = type => {
    if (type === 'generic') {
        return {
            border: '1px solid ' + colors.blue,
            background: 'white',
        };
    }
    if (type === 'missing') {
        return {
            border: '1px dashed ' + colors.blue,
        };
    }
    return {
        background: colors.blue,
    };
};

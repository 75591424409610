import React from 'react';
import { colors } from '../modules/theme';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import { scaleLinear } from 'd3-scale';
const levelToText = {
    1: 'Bachelor degree or higher qualification. At least five years of relevant experience may substitute for the formal qualification. In some instances relevant experience and/or on-the-job training may be required in addition to the formal qualification.',
    2: 'NZ Register Diploma, AQF Associate Degree, Advanced Diploma or Diploma. At least three years of relevant experience may substitute for the formal qualifications listed above. In some instances relevant experience and/or on-the-job-training may be required in addition to the formal qualification.',
    3: 'NZ Register Level 4 qualification, AQF Certificate IV or AQF Certificate III including at least two years of on-the job training. At least three years of relevant experience may substitute for the formal qualifications listed above. In some instances relevant experience and/or on-the-job-training may be required in addition to the formal qualification.',
    4: 'NZ Register Level 2 or 3 qualification or AQF Certificate II or III. At least one year of relevant experience may substitute for the formal qualifications listed above. In some instances relevant experience may be required in addition to the formal qualification.',
    5: 'NZ Register Level 1 qualification, AQF Certificate I or compulsory secondary education. For some occupations a short period of on-the-job training may be required in addition to or instead of the formal qualification. In some instances, no formal qualification or on-the-job training may be required.',
};

const scale = scaleLinear().range([0, 100]);

const OccupationLabelAndWage = ({ label, wage, left, bottom, textAlign }) => {
    const translateX = {
        center: '-50',
        right: '-100',
        left: 0,
    };
    return (
        <div
            css={{
                position: 'absolute',
                left: left + '%',
                transform: `translateX(${translateX[textAlign]}%)`,
                bottom: bottom,
                textAlign,
                width: 400,
            }}
        >
            <i>{label}</i>
            <p css={{ margin: 5 }}>
                $
                {wage.toLocaleString({
                    currency: 'AUD',
                })}
            </p>
        </div>
    );
};

const WageChart = ({ occupation, currentOccupation }) => {
    scale.domain([0, currentOccupation.yearlyWage * 2]).clamp(true);
    const occupationLeft = scale(occupation.yearlyWage);
    const currentOccupationLeft = scale(currentOccupation.yearlyWage);
    return (
        <div
            css={{
                position: 'relative',
                height: 130,
                fontSize: 12,
            }}
        >
            <OccupationLabelAndWage
                wage={occupation.yearlyWage}
                left={occupationLeft}
                label={occupation.label}
                textAlign={
                    Math.abs(occupationLeft - currentOccupationLeft) > 25
                        ? occupation.yearlyWage < currentOccupation.yearlyWage
                            ? 'left'
                            : 'right'
                        : 'center'
                }
            />
            <div
                css={{
                    top: 50,
                    background: colors.whiteBlue,
                    height: 16,
                    width: '100%',
                    position: 'relative',
                }}
            >
                <div
                    css={{
                        backgroundImage: `url('/images/line-pattern.svg')`,
                        border: `1px solid ${colors.lightBlue}`,
                        height: 16,
                        position: 'absolute',
                        left:
                            Math.min(occupationLeft, currentOccupationLeft) +
                            '%',
                        width: `${Math.abs(
                            occupationLeft - currentOccupationLeft
                        )}%`,
                    }}
                />
            </div>
            <div
                css={{
                    backgroundColor: colors.blue,
                    height: 32,
                    position: 'absolute',
                    left: Math.min(occupationLeft, currentOccupationLeft) + '%',
                    width: 4,
                    top: 42,
                }}
            />
            <OccupationLabelAndWage
                bottom={10}
                wage={currentOccupation.yearlyWage}
                left={currentOccupationLeft}
                textAlign="center"
                label={currentOccupation.label}
            />
            <div
                css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    bottom: 40,
                    position: 'absolute',
                    width: '100%',
                }}
            >
                <span>$0</span>

                <span>
                    $
                    {(currentOccupation.yearlyWage * 2).toLocaleString({
                        currency: 'AUD',
                    })}
                </span>
            </div>
        </div>
    );
};

function InfoSection({ occupation, currentOccupation, skillLevel }) {
    return (
        <div
            css={{
                display: 'flex',
                margin: '20px 0',
                padding: 30,
                border: `1px solid ${colors.whiteBlue}`,
            }}
        >
            <div
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                    marginRight: 25,
                }}
            >
                <div css={{ display: 'flex' }}>
                    <div>
                        <h4>Description</h4>
                        <p>{occupation.ANZSCO_MAPPED_DESC}</p>
                    </div>
                </div>
                <div>
                    <h4>Wage</h4>
                    {currentOccupation ? (
                        <WageChart
                            occupation={occupation}
                            currentOccupation={currentOccupation}
                        />
                    ) : (
                        <p>
                            {' '}
                            $
                            {occupation.yearlyWage.toLocaleString({
                                currency: 'AUD',
                            })}
                        </p>
                    )}
                </div>
            </div>
            <div css={{ width: '50%', marginLeft: 25 }}>
                <h4>Education</h4>
                {skillLevel && (
                    <p>
                        <b>Level {skillLevel}</b> - {levelToText[skillLevel]}
                    </p>
                )}
            </div>
        </div>
    );
}
export default connect((state, props) => {
    const levelByOccupationCode = state.data.levelByOccupationCode;
    const occupationsByCode = state.data.occupationsByCode;
    const currentCode = get('ANZSCO_MAPPED_CODE', props.currentOccupation);
    return {
        currentOccupation: get(currentCode, occupationsByCode),
        skillLevel: get(
            [props.occupation.id, 'SKILL_LEVEL'],
            levelByOccupationCode
        ),
    };
})(InfoSection);

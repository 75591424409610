import React from 'react';
import { colors, fontSizes, fontWeights } from '../modules/theme';
import { width as LANDING_VIS_WIDTH } from './landing-force-vis/landing-force-vis';

function SkillLegendItem({ heading, subHeading, svgPath }) {
    const dotColumnWidth = 35;
    return (
        <div>
            <div
                css={{
                    display: 'grid',
                    gridTemplateColumns: `${dotColumnWidth}px 1fr`,
                    alignItems: 'center',
                    marginTop: 10,
                }}
            >
                <div
                    css={{
                        display: 'grid',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img src={svgPath} alt={heading} />
                </div>
                <p
                    css={{
                        textTransform: 'uppercase',
                        fontWeight: fontWeights.bold,
                        display: 'block',
                        fontSize: fontSizes.small,
                        margin: 0,
                    }}
                >
                    {heading}
                </p>
            </div>
            <div>
                {subHeading ? (
                    <div
                        css={{
                            marginLeft: dotColumnWidth,
                            fontSize: fontSizes.small,
                        }}
                    >
                        {subHeading}
                    </div>
                ) : null}
            </div>
        </div>
    );
}

function MatchLegendItem({ heading, svgPath }) {
    return (
        <div
            css={{
                display: 'grid',
                gridTemplateColumns: '50% 50%',
                alignItems: 'center',
                fontSize: fontSizes.small,
                marginTop: 10,
            }}
        >
            <div>
                <p css={{ margin: 0 }}>{heading}</p>
            </div>
            <div>
                <img src={svgPath} alt={heading} />
            </div>
        </div>
    );
}

function LandingPageLegend(props) {
    // Negative marginTop on top so that the legend "sits around" the vis a little
    // Rather than just below
    return (
        <div
            css={{
                display: 'flex',
                width: LANDING_VIS_WIDTH * 1.2,
                justifyContent: 'space-between',
                marginTop: -130,
                marginBottom: 50,
                marginLeft: 'auto',
                marginRight: 'auto',
                color: colors.blue,
                alignItems: 'flex-end',
            }}
        >
            <div>
                <SkillLegendItem
                    heading="Current job"
                    subHeading={null}
                    svgPath="/images/current-job.svg"
                />
                <SkillLegendItem
                    heading="Past jobs"
                    subHeading={null}
                    svgPath="/images/past-jobs.svg"
                />
                <SkillLegendItem
                    heading="Similar jobs"
                    subHeading={null}
                    svgPath="/images/similar-jobs.svg"
                />
                <SkillLegendItem
                    heading="Existing skill"
                    subHeading="Detailed work activities involved in this job"
                    svgPath="/images/existing-skill.svg"
                />
                <SkillLegendItem
                    heading="Transferable skill"
                    subHeading="Skills from your current job that you can apply"
                    svgPath="/images/transferrable-skill.svg"
                />
            </div>
            <div css={{ width: 250 }}>
                <div>
                    <p
                        css={{
                            textTransform: 'uppercase',
                            fontWeight: fontWeights.bold,
                            fontSize: fontSizes.small,
                            margin: 0,
                        }}
                    >
                        Similarity of jobs
                    </p>
                </div>
                <MatchLegendItem
                    heading="Strong Match"
                    svgPath="/images/strong-match.svg"
                />
                <MatchLegendItem
                    heading="Moderate match"
                    svgPath="/images/moderate-match.svg"
                />
                <MatchLegendItem
                    heading="Weak Match"
                    svgPath="/images/weak-match.svg"
                />
            </div>
        </div>
    );
}

export default LandingPageLegend;

import React, { useRef, useState, useEffect, useMemo } from 'react';
import { colors } from '../../modules/theme';
import { attachPretendOpacity } from '../../modules/attachPretendOpacity';
import {
    getData,
    xPosForOccupation,
    isMissingSkill,
    isTransferableSkill,
} from './helpers';
import {
    forceSimulation,
    forceLink,
    forceCollide,
    forceX,
    forceY,
    forceManyBody,
} from 'd3-force';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import dontGoInTheRectangles from '../../modules/dont-go-in-the-rectangles-force.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
import Popup from './popup';
import { get, mean } from 'lodash/fp';
import dragDrop from '../../modules/drag-drop';
import { removeFutureOccupationCode } from '../../ducks/ui';
import useArcs from './use-arcs';
import { Link } from 'react-router-dom';
import { event } from 'd3-selection';

const { blue, lightBlue } = colors;

const height = 500;
const occupationCircleSize = 30;
const skillsSize = 5;
const forceYStrengthScale = scaleLinear().range([0.03, 0.05]);
const clusterSize = 275;
const circleStroke = 1;
const useSimulation = (showingOccupationCodes, textRectangles, width) => {
    return useMemo(() => {
        const simulationInstance = forceSimulation();

        simulationInstance.stop();
        simulationInstance
            .force(
                'link',
                forceLink()
                    .id(function(node) {
                        return node.id;
                    })
                    .distance(x => 48)
                    .strength(0)
            )
            .force('charge', forceManyBody())
            .force(
                'force-x',
                forceX(d => {
                    if (d.type === 'occupation') {
                        return 0;
                    }

                    const forOccupationsShowing = d.forOccupations.filter(
                        code => showingOccupationCodes.includes(code)
                    );
                    const xPositions = forOccupationsShowing.map(code => {
                        const index = showingOccupationCodes.indexOf(code);
                        return xPosForOccupation(
                            width,
                            showingOccupationCodes,
                            index
                        );
                    });
                    if (xPositions.length === 0) {
                        // This means that its a previous skill not connected to any of the
                        // showing occupaitons. Lets position it close to current occupation (but a little more left)
                        const pixelMoreToTheLeftThanCurrentOccupation = 30;
                        return (
                            xPosForOccupation(
                                width,
                                showingOccupationCodes,
                                0
                            ) - pixelMoreToTheLeftThanCurrentOccupation
                        );
                    }
                    return mean(xPositions);
                })
            )
            .force(
                'force-y',
                forceY(d => height / 2).strength(d => {
                    if (d.type === 'occupation') {
                        return forceYStrengthScale.range()[1];
                    }
                    return forceYStrengthScale(d.hoursPerWeek);
                })
            )
            .force(
                'collisionForce',
                forceCollide()
                    .strength(1)
                    .iterations(100)
                    .radius(d => {
                        const size =
                            d.type === 'skill'
                                ? skillsSize
                                : occupationCircleSize;
                        const padding = 5;
                        return size + padding;
                    })
            )
            .force(
                'rect1',
                dontGoInTheRectangles(
                    textRectangles.map(x => x.rectangle),
                    skillsSize
                )
            );
        simulationInstance.alpha(1).restart();
        return simulationInstance;
    }, [showingOccupationCodes, textRectangles, width]);
};
function CareerPathVis({
    skillsByName,
    showingOccupationCodes,
    match,
    removeFutureOccupationCode,
    skillsByOccupationCode,
    occupationsByCode,
    addedSkills,
    removedSkills,
    data,
    prevOccupationsCodes,
}) {
    const nodesRef = useRef();
    const linksRef = useRef();
    const [hoveredCircle, setHoveredCircle] = useState();
    const width = clusterSize * showingOccupationCodes.length;

    const textRectangles = useMemo(() => {
        return data.nodes
            .filter(n => n.type === 'occupation')
            .map((node, index) => {
                const width = 200;
                const height = 30; // TODO maybe need a better way to figure out height
                const paddingTop = 10;
                const x = node.fx - width / 2;
                const y = node.fy + occupationCircleSize + paddingTop;
                return { node, rectangle: [[x, y], [x + width, y + height]] };
            });
    }, [data.nodes]);
    const simulation = useSimulation(
        showingOccupationCodes,
        textRectangles,
        width
    );

    useEffect(() => {
        const linkContainer = select(linksRef.current);
        const nodesContainer = select(nodesRef.current);

        var linkSelection = linkContainer.selectAll('line').data(data.links);
        linkSelection.exit().remove();
        linkSelection = linkSelection
            .enter()
            .append('line')
            .attr('stroke-width', 2)
            .attr('stroke', lightBlue)
            .attr('border-style', 'dashed')
            .merge(linkSelection)
            .attr('opacity', d => {
                if (!hoveredCircle) return 0.2;
                if (
                    d.target.id === hoveredCircle.id ||
                    d.source.id === hoveredCircle.id
                ) {
                    return 1;
                }
                return 0.2;
            });

        const getNodeOpacity = d => {
            if (!hoveredCircle) return 1;
            if (hoveredCircle.forOccupations.includes(d.id)) return 1;
            return d.id === hoveredCircle.id ? 1 : 0.2;
        };

        var nodeSelection = nodesContainer
            .selectAll('g')
            .data(data.nodes, d => d.id);

        nodeSelection.exit().remove();

        nodeSelection = nodeSelection
            .enter()
            .append('g')
            .merge(nodeSelection)
            .attr('data-label', d => d.label);

        let skillCircleSelection = nodeSelection
            .filter(x => x.type === 'skill')
            .selectAll('.skill-circle')
            .data(d => [d]);
        skillCircleSelection.exit().remove();

        skillCircleSelection = skillCircleSelection
            .enter()
            .append('circle')
            .merge(skillCircleSelection)
            .attr('class', 'skill-circle ')
            .attr('r', skillsSize)
            .attr('fill', d => {
                // uncmomment to check whats skills are past skills
                // if (
                //     d.forOccupations.some(code =>
                //         prevOccupationsCodes.includes(code)
                //     )
                // ) {
                //     return 'red';
                // }
                if (isTransferableSkill(showingOccupationCodes, d)) {
                    return blue;
                }
                return 'white';
            })
            .attr('stroke', blue)
            .attr('opacity', d => {
                if (!hoveredCircle) return 1;
                return d.id === hoveredCircle.id ? 1 : 0.2;
            })
            .attr('stroke-dasharray', d => {
                if (isMissingSkill(showingOccupationCodes, d)) {
                    return 4;
                }
            })
            .on('mouseover', d => {
                event.stopPropagation();

                if (d.id === get('id', hoveredCircle)) return;
                simulation.stop();

                setHoveredCircle(d);
            })
            .call(dragDrop(simulation));

        var outerCircleSelection = nodeSelection
            .filter(x => x.type === 'occupation')
            .selectAll('.outer-circle')
            .data(d => [d]);
        outerCircleSelection.exit().remove();

        outerCircleSelection = outerCircleSelection
            .enter()
            .append('circle')
            .attr('class', d => d.type + ' outer-circle ')
            .merge(outerCircleSelection)
            .attr('r', occupationCircleSize)
            .attr('fill', colors.whiteBlue)
            .attr('stroke', d => {
                const opacity = getNodeOpacity(d);
                return showingOccupationCodes[0] === d.ANZSCO_MAPPED_CODE
                    ? attachPretendOpacity(blue, opacity)
                    : attachPretendOpacity(colors.lightBlue, opacity);
            });

        let innerCircleSelection = nodeSelection
            .filter(d => d.type === 'occupation')
            .selectAll('.inner-circle')
            .data(d => [d]);

        innerCircleSelection.exit().remove();

        innerCircleSelection = innerCircleSelection
            .enter()
            .append('circle')
            .merge(innerCircleSelection)
            .attr('class', 'inner-circle')
            .attr('r', occupationCircleSize * 0.8)
            .attr('fill', d => {
                const opacity = getNodeOpacity(d);
                return showingOccupationCodes[0] === d.ANZSCO_MAPPED_CODE
                    ? attachPretendOpacity(blue, opacity)
                    : attachPretendOpacity(colors.lightBlue, opacity);
            });

        // Simulation tick helper functions
        const getSize = d => {
            const radiusWithoutStroke =
                d.type === 'skill' ? skillsSize : occupationCircleSize;
            return radiusWithoutStroke + circleStroke * 2;
        };
        // These x and y functions make sure the circles never goes outside the svg
        // inspo https://bl.ocks.org/mbostock/1129492
        const x = d => {
            const size = getSize(d);
            return (d.x = Math.max(size, Math.min(width - size, d.x)));
        };
        const y = d => {
            const size = getSize(d);
            return (d.y = Math.max(size, Math.min(height - size, d.y)));
        };
        simulation.nodes(data.nodes).on('tick', () => {
            requestAnimationFrame(() => {
                skillCircleSelection.attr('cx', x).attr('cy', y);
                innerCircleSelection.attr('cx', x).attr('cy', y);
                outerCircleSelection.attr('cx', x).attr('cy', y);
                linkSelection
                    .attr('x1', get(['source', 'x']))
                    .attr('y1', get(['source', 'y']))
                    .attr('x2', get(['target', 'x']))
                    .attr('y2', get(['target', 'y']))
                    .attr('id', get(['target', 'id']));
            });
        });
    }, [
        data.links,
        data.nodes,
        hoveredCircle,
        prevOccupationsCodes,
        showingOccupationCodes,
        simulation,
        width,
    ]);

    const arcsRef = useRef();
    const dataForArcs = data.nodes.filter(x => x.type === 'occupation');
    useArcs(arcsRef, dataForArcs, hoveredCircle);
    return (
        <div
            css={{
                width,
                height,
            }}
        >
            <div
                css={{
                    width,
                    height,
                    position: 'relative',
                }}
            >
                <svg
                    onMouseOver={e => {
                        if (hoveredCircle) {
                            setHoveredCircle(null);
                        }
                        simulation.restart();
                    }}
                    width={width}
                    height={height}
                >
                    <g>
                        <g className="archBetweenOccupations" ref={arcsRef} />
                        <g className="links" ref={linksRef} />
                        <g className="nodes" ref={nodesRef} />
                        {/* This is uselful to debug the rectangle we use to make sure circles dont end up behind text */}
                        {/* {textRectangles.map(({ rectangle }, i) => {
                            const [[x, y], [x1, y1]] = rectangle;
                            return (
                                <rect
                                    key={i}
                                    x={x}
                                    y={y}
                                    width={x1 - x}
                                    height={y1 - y}
                                    fill="none"
                                />
                            );
                        })} */}
                    </g>
                </svg>
                {hoveredCircle && (
                    <Popup
                        showingOccupationCodes={showingOccupationCodes}
                        hoveredCircle={hoveredCircle}
                        currentOccupation={showingOccupationCodes[0]}
                        skillsByName={skillsByName}
                        top={hoveredCircle.y + (skillsSize + circleStroke * 2)}
                        left={hoveredCircle.x}
                        prevOccupationsCodes={prevOccupationsCodes}
                    />
                )}
                {textRectangles.map(({ rectangle, node }, i) => {
                    const [[x, y], [x1]] = rectangle;

                    return (
                        <div
                            className={'rectangle-' + i}
                            key={i}
                            css={{
                                fill: 'rgba(255,255,255,0.5)',
                                border: `1px solid ${colors.whiteBlue}`,
                                borderRadius: '2px',
                                position: 'absolute',
                                top: y,
                                left: x,
                                width: x1 - x,
                                height: 42,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '5px 10px',
                                fontWeight: 'bold',
                                background: 'white',
                            }}
                        >
                            <Link
                                title={node.label}
                                to={`/career-path/${match.params.alternateOccupationId}/occupations/${node.id}`}
                                css={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textAlign: 'center',
                                    width: '100%',
                                    fontSize: 12,
                                    margin: 0,
                                    color: colors.darkBlue,
                                    textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    },
                                }}
                            >
                                {node.label}
                            </Link>

                            {node.ANZSCO_MAPPED_CODE !==
                                showingOccupationCodes[0] && (
                                <button
                                    css={{
                                        border: 'none',
                                        background: 'none',
                                        color: lightBlue,
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            textDecoration: 'underline',
                                        },
                                    }}
                                    onClick={() => {
                                        simulation.stop();
                                        removeFutureOccupationCode(node.id);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                    {'  '}
                                    DELETE
                                </button>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default connect(
    state => {
        const currentOccupation = get(
            'ANZSCO_MAPPED_CODE',
            state.ui.alternateTitleCurrentOccupation
        );
        const showingOccupationCodes = currentOccupation
            ? [currentOccupation].concat(state.ui.futureOccupations)
            : [];
        const { addedSkills, removedSkills } = state.ui;
        const occupationsByCode = state.data.occupationsByCode;
        const skillsByName = state.data.skillsByName;
        const skillsByOccupationCode = state.data.skillsByOccupationCode;
        const width = clusterSize * showingOccupationCodes.length;
        const prevOccupationsCodes = state.ui.alternateTitlePastOccupations.map(
            x => x.ANZSCO_MAPPED_CODE
        );
        return {
            data: getData(
                width,
                height,
                skillsByOccupationCode,
                skillsByName,
                occupationsByCode,
                showingOccupationCodes,
                addedSkills,
                removedSkills,
                prevOccupationsCodes
            ),
            prevOccupationsCodes,
            occupationsByCode: state.data.occupationsByCode,
            skillsByName: state.data.skillsByName,
            skillsByOccupationCode: state.data.skillsByOccupationCode,
            addedSkills,
            removedSkills,
            showingOccupationCodes,
        };
    },
    {
        removeFutureOccupationCode,
    }
)(CareerPathVis);

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { colors } from '../modules/theme';

export default function BackButton({ to }) {
    return (
        <Link
            css={{
                color: colors.lightBlue,
                textDecoration: 'none',
                fontSize: 10,
                margin: '20px 0',
                display: 'block',
            }}
            to={to}
        >
            <FontAwesomeIcon icon={faArrowLeft} /> BACK TO SEARCH
        </Link>
    );
}

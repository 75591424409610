import React from 'react';
import { colors } from '../../modules/theme';

export const BlueHeading = props => {
    return (
        <h3
            css={{
                background: colors.darkBlue,
                padding: 10,
                display: 'flex',
                justifyContent: 'space-between',
                color: 'white',
                borderRadius: '2px',
            }}
            {...props}
        >
            {props.children}
        </h3>
    );
};

export const Circle = props => {
    return (
        <span
            css={{
                width: 15,
                height: 15,
                borderRadius: '100%',
                backgroundColor: 'white',
                flexShrink: 0,
            }}
            {...props}
        />
    );
};
export const PopupHeader = props => {
    return (
        <div
            css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginBottom: '10px',
                fontSize: '12px',
                fontWeight: '500',
                letterSpacing: '0.5px',
                lineHeight: '16px',
            }}
        >
            {props.icon}
            <h4
                css={{
                    textTransform: 'uppercase',
                    color: colors.lightBlue,
                    margin: '0 0 0 5px',
                }}
            >
                {props.headline}
            </h4>
        </div>
    );
};
export const PopupParagraph = props => (
    <p
        css={{
            color: colors.darkBlue,
            fontSize: '14px',
            lineHeight: '18px',
            margin: 0,
        }}
        {...props}
    />
);

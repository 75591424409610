import { drag } from 'd3-drag';
import { event } from 'd3-selection';
const noop = () => {};
const dragDrop = (simulation, ignoreFn = noop) =>
    drag()
        .on('start', function(node) {
            if (ignoreFn(node)) return;
            node.fx = node.x;
            node.fy = node.y;
        })
        .on('drag', function(node) {
            if (ignoreFn(node)) return;
            simulation.alphaTarget(0.7).restart();
            node.fx = event.x;
            node.fy = event.y;
        })
        .on('end', function(node) {
            if (ignoreFn(node)) return;
            if (!event.active) {
                simulation.alphaTarget(0);
            }
            node.fx = null;
            node.fy = null;
        });

export default dragDrop;

import React from 'react';
import { orderBy } from 'lodash/fp';
import { colors, fontSizes, fontWeights } from '../../modules/theme';
import { Circle, PopupHeader, PopupParagraph } from '../../component/ui';
import PopupWrapper from '../popup-wrapper';
import { isTransferableSkill, isMissingSkill } from './helpers';

const { lightBlue, blue } = colors;
const { small } = fontSizes;
const { bold } = fontWeights;

const popUpTableHeaderStyle = {
    color: blue,
    fontWeight: bold,
    fontSize: small,
    marginBottom: '5px',
};

const TransferableSkill = ({
    hoveredCircle,
    skillsByName,
    showingOccupationCodes,
    prevOccupationsCodes,
}) => {
    return (
        <div>
            <PopupHeader
                headline="Transferable skill"
                icon={
                    <img
                        src="/images/transferable-skill-pathway.svg"
                        alt="transferable skill"
                    />
                }
            />
            <PopupParagraph>{hoveredCircle.label}</PopupParagraph>
            <div>
                <div
                    css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 5,
                    }}
                >
                    <span css={popUpTableHeaderStyle}>Job</span>
                    <span css={popUpTableHeaderStyle}>Hours/week</span>
                </div>
                {orderBy(
                    'hoursPerWeek',
                    ['desc'],
                    skillsByName[hoveredCircle.label]
                )
                    .filter(x =>
                        prevOccupationsCodes
                            .concat(showingOccupationCodes)
                            .includes(x.ANZSCO_MAPPED_CODE)
                    )
                    .map((skill, i) => {
                        return (
                            <div
                                key={i}
                                css={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    color: lightBlue,
                                    fontSize: '0.9em',
                                }}
                            >
                                <span>{skill.ANZSCO_MAPPED_TITLE}</span>
                                <span>
                                    {oneDecimalPlace(skill.hoursPerWeek)}
                                </span>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

const ExistingSkill = ({ hoveredCircle }) => {
    return (
        <div>
            <PopupHeader
                headline="Existing Skill"
                icon={
                    <Circle
                        css={{
                            backgroundColor: 'white',
                            border: `1px solid ${blue}`,
                            height: '15px',
                            width: '15px',
                        }}
                    />
                }
            />

            <PopupParagraph>{hoveredCircle.label}</PopupParagraph>
        </div>
    );
};
const MissingSkill = ({ hoveredCircle }) => {
    return (
        <div>
            <PopupHeader
                icon={
                    <Circle
                        css={{
                            backgroundColor: 'white',
                            border: `1px dashed ${blue}`,
                            height: '15px',
                            width: '15px',
                        }}
                    />
                }
                headline="Missing Skill"
            />
            <PopupParagraph>{hoveredCircle.label}</PopupParagraph>
        </div>
    );
};

const RenderPopupContent = props => {
    const { hoveredCircle, showingOccupationCodes } = props;

    if (isTransferableSkill(showingOccupationCodes, hoveredCircle)) {
        return <TransferableSkill {...props} />;
    }

    if (isMissingSkill(showingOccupationCodes, hoveredCircle)) {
        return <MissingSkill {...props} />;
    }
    return <ExistingSkill {...props} />;
};

export default function Popup({
    currentOccupation,
    hoveredCircle,
    skillsByName,
    showingOccupationCodes,
    prevOccupationsCodes,
    top,
    left,
    onMouseLeave,
}) {
    return (
        <PopupWrapper top={top} left={left} onMouseLeave={onMouseLeave}>
            <RenderPopupContent
                currentOccupation={currentOccupation}
                hoveredCircle={hoveredCircle}
                skillsByName={skillsByName}
                showingOccupationCodes={showingOccupationCodes}
                prevOccupationsCodes={prevOccupationsCodes}
            />
        </PopupWrapper>
    );
}

const oneDecimalPlace = number => {
    return number.toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    });
};

import React from 'react';
import { colors, fontWeights } from '../modules/theme';
import { Circle } from './ui';

const P = props => {
    return (
        <p css={{ margin: 0, fontSize: '12px' }} {...props}>
            {props.children}
        </p>
    );
};

const legendHeaderStyle = {
    fontWeight: fontWeights.bold,
    textTransform: 'uppercase',
    marginLeft: '10px',
};

export default function CareerPathLegend() {
    return (
        <div
            css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 20,
            }}
        >
            <div css={{ display: 'flex', margin: '0 10px' }}>
                <Circle
                    css={{
                        border: '1px solid ' + colors.blue,
                    }}
                />
                <div css={{ display: 'flex', flexDirection: 'column' }}>
                    <P css={legendHeaderStyle}>Existing skill</P>
                    <P css={{ marginLeft: '10px' }}>
                        Detailed work activities involved in this job
                    </P>
                </div>
            </div>
            <div css={{ display: 'flex', margin: '0 10px' }}>
                <Circle
                    css={{
                        background: colors.blue,
                    }}
                />
                <div css={{ display: 'flex', flexDirection: 'column' }}>
                    <P css={legendHeaderStyle}>Transferable skill</P>
                    <P css={{ marginLeft: '10px' }}>
                        Skills gained in one job that you can apply to the next
                    </P>
                </div>
            </div>
            <div css={{ display: 'flex', margin: '0 10px' }}>
                <Circle
                    css={{
                        border: '1px dashed ' + colors.blue,
                    }}
                />
                <div css={{ display: 'flex', flexDirection: 'column' }}>
                    <P css={legendHeaderStyle}>Skill gap</P>
                    <P css={{ marginLeft: '10px' }}>
                        Skills you need to gain for this job
                    </P>
                </div>
            </div>
        </div>
    );
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../modules/theme';
import { typeToCircleStyle } from '../modules/skill-circle';
import { Circle } from '../component/ui';

const hoursPerWeekString = number => {
    // 1 decimal place
    number = number.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
    });
    return number + (number === '1' ? ' hr/week' : ' hrs/week');
};

export default function TechnicalSkillsList({
    skills,
    skillType,
    scale,
    onClick,
    icon,
    border = true,
}) {
    if (skills.length === 0) return <p css={{ paddingLeft: 20 }}>No skills.</p>;

    return (
        <ul>
            {skills.map(skill => {
                return (
                    <li
                        css={{
                            border:
                                border && `2px solid ${colors.veryLightBlue}`,
                            display: 'grid',
                            gridTemplateColumns: '70px 1fr 40px',
                            alignItems: 'center',
                            marginLeft: 0,
                            marginBottom: '10px',
                            borderRadius: '3px',
                        }}
                        key={skill.id}
                    >
                        <div css={{ display: 'grid', justifyItems: 'center' }}>
                            <Circle
                                css={{
                                    ...typeToCircleStyle(skillType),
                                    width: scale(skill.hoursPerWeek),
                                    height: scale(skill.hoursPerWeek),
                                }}
                            />
                        </div>
                        <div
                            css={{
                                display: 'grid',
                                gridTemplateRows: 'auto auto',
                                gridRowGap: '10px',
                                padding: '15px 0px 15px 0px',
                            }}
                        >
                            <div
                                css={{
                                    fontWeight: '600',
                                }}
                            >
                                <p css={{ margin: '0 0 0 0' }}>{skill.label}</p>
                            </div>
                            <div
                                css={{
                                    color: colors.lightBlue,
                                    fontSize: '0.9em',
                                    display: 'grid',
                                    alignContent: 'center',
                                }}
                            >
                                <p css={{ margin: '0 0 0 0' }}>
                                    <span>requires </span>
                                    <span css={{ fontWeight: 600 }}>
                                        {hoursPerWeekString(skill.hoursPerWeek)}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div
                            css={{
                                color: colors.lightBlue,
                                display: 'grid',
                                alignContent: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {onClick && (
                                <div
                                    css={{
                                        height: '20px',
                                        width: 'auto',
                                        display: 'grid',
                                        alignContent: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => onClick(skill)}
                                >
                                    <FontAwesomeIcon icon={icon} />
                                </div>
                            )}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { colors } from '../../modules/theme';
import { addFutureOccupationCode } from '../../ducks/ui';
import TechnicalSkills from './technical-skills';
import InfoSection from '../../component/occupation-info-section';
import CoreSkills from './core-skills';
import {
    fetchSkillLevels,
    fetchCoreSkills,
    fetchTechnologyTools,
} from '../../ducks/data';
import BackButton from '../../component/back-button';
import LoadingAnimation from '../../component/loading';
import TechnologyTools from './technology-tools';

const PastExperienceText = ({ pastOccupationNames }) => {
    if (pastOccupationNames.length === 0) return '';

    return (
        <>
            and past experience{pastOccupationNames.length > 1 ? 's' : ''} (
            {pastOccupationNames.join(', ')})
        </>
    );
};

function Occupation({
    occupation,
    location,
    match,
    currentOccupation,
    addFutureOccupationCode,
    fetchSkillLevels,
    fetchCoreSkills,
    coreSkillsByOccupationCode,
    fetchTechnologyTools,
    pastOccupationNames,
    futureOccupations,
}) {
    useEffect(() => {
        fetchSkillLevels();
        fetchCoreSkills();
        fetchTechnologyTools();
    }, [fetchSkillLevels, fetchCoreSkills, fetchTechnologyTools]);

    if (!currentOccupation)
        return (
            <Redirect
                to={`/career-path/${match.params.alternateOccupationId}`}
            />
        );

    const occupationIsCurrentOrFuture =
        futureOccupations.includes(parseInt(match.params.occupationCode)) ||
        currentOccupation.ANZSCO_MAPPED_CODE ===
            parseInt(match.params.occupationCode);
    return (
        <div>
            {!occupationIsCurrentOrFuture && (
                <BackButton
                    to={`/career-path/${match.params.alternateOccupationId}`}
                />
            )}

            {!coreSkillsByOccupationCode ? (
                <LoadingAnimation />
            ) : (
                <div>
                    <div
                        css={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            <h2>{occupation.label}</h2>
                            <p>
                                compared to your current job as a{' '}
                                {currentOccupation.ANZSCO_MAPPED_TITLE}{' '}
                                <PastExperienceText
                                    pastOccupationNames={pastOccupationNames}
                                />
                            </p>
                        </div>

                        <Link
                            onClick={() => {
                                if (futureOccupations.length === 3) return;
                                addFutureOccupationCode(occupation.id);
                            }}
                            to={`/career-path/${match.params.alternateOccupationId}/vis/${match.params.occupationCode}`}
                            css={{
                                border: `2px solid ${colors.darkBlue}`,
                                color: colors.darkBlue,
                                padding: '10px 12px',
                                flexBasis: 250,
                                fontSize: '14px',
                                textDecoration: 'none',
                                textAlign: 'center',
                                fontWeight: 'bold',
                            }}
                        >
                            {occupationIsCurrentOrFuture ||
                            futureOccupations.length === 3
                                ? 'Show'
                                : 'Add to'}{' '}
                            career pathway
                        </Link>
                    </div>
                    <InfoSection
                        currentOccupation={currentOccupation}
                        occupation={occupation}
                    />
                    <div css={{ display: 'flex', alignItems: 'flex-start' }}>
                        <CoreSkills match={match} />
                        <div>
                            <TechnicalSkills match={match} />
                            <TechnologyTools match={match} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default connect(
    (state, props) => {
        const occupationsByCode = state.data.occupationsByCode;
        return {
            occupation: occupationsByCode[props.match.params.occupationCode],
            currentOccupation: state.ui.alternateTitleCurrentOccupation,
            futureOccupations: state.ui.futureOccupations,
            pastOccupationNames: state.ui.alternateTitlePastOccupations.map(
                x => x.ANZSCO_MAPPED_TITLE
            ),
            coreSkillsByOccupationCode: state.data.coreSkillsByOccupationCode,
        };
    },
    {
        addFutureOccupationCode,
        fetchTechnologyTools,
        fetchSkillLevels,
        fetchCoreSkills,
    }
)(Occupation);

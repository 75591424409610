import React from 'react';
import CareerPathVis from '../component/career-path-vis';
import AddOccupationVis from '../component/add-occupation-vis';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { get, last } from 'lodash/fp';
import CareerPathLegend from '../component/career-path-legend';
import { colors } from '../modules/theme';
import { Link } from 'react-router-dom';
import { resetFutureOccupations } from '../ducks/ui';

function CareerPathPathway(props) {
    if (!props.currentOccupation)
        return (
            <Redirect
                to={`/career-path/${props.match.params.alternateOccupationId}`}
            />
        );

    return (
        <div css={{ paddingBottom: 100 }}>
            <div
                css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    martingTop: 15,
                }}
            >
                <h2>My career pathway to {props.title} </h2>
                <Link
                    onClick={e => props.resetFutureOccupations()}
                    css={{
                        border: `2px solid ${colors.darkBlue}`,
                        color: colors.darkBlue,
                        padding: '10px 12px',
                        flexBasis: 250,
                        fontSize: '14px',
                        textDecoration: 'none',
                        textAlign: 'center',
                        fontWeight: 'bold',
                    }}
                    to={`/career-path/${
                        props.match.params.alternateOccupationId
                    }`}
                >
                    Start a new career pathway
                </Link>
            </div>
            <div
                css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CareerPathVis {...props} />
                {props.futureOccupations.length < 3 && (
                    <AddOccupationVis
                        key={
                            props.futureOccupations.length > 0
                                ? last(props.futureOccupations)
                                : props.currentOccupation
                        }
                    />
                )}
            </div>
            <CareerPathLegend />
        </div>
    );
}

const mapStateToProps = state => {
    const currentOccupation = get(
        'ANZSCO_MAPPED_CODE',
        state.ui.alternateTitleCurrentOccupation
    );
    const futureOccupations = state.ui.futureOccupations;
    const codeForTitle =
        futureOccupations.length > 0
            ? last(futureOccupations)
            : currentOccupation;

    const title = get('label', state.data.occupationsByCode[codeForTitle]);
    return {
        currentOccupation,
        futureOccupations: state.ui.futureOccupations,
        title,
    };
};
const mapDispatchToProps = {
    resetFutureOccupations,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CareerPathPathway);

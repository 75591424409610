import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const AsyncSelect = props => {
    const [loadingForInputValues, setLoadingForInputValues] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        props.worker.onmessage = ({ data }) => {
            setLoadingForInputValues(loadingInputValues =>
                loadingInputValues.filter(val => val !== data.inputValue)
            );
            if (data.options !== null) {
                setOptions(data.options);
            }
        };
    }, [props.name, props.worker.onmessage]);
    return (
        <Select
            cacheOptions={false}
            filterOption={() => true}
            isLoading={loadingForInputValues.length}
            options={options}
            onInputChange={(inputValue, { action }) => {
                if (action !== 'input-change') return;

                if (inputValue.length < 3) {
                    setOptions([]);
                    setLoadingForInputValues([]);
                    return;
                }
                setLoadingForInputValues(x => x.concat(inputValue));
                props.worker.postMessage({
                    type: 'FILTER',
                    payload: inputValue,
                });
            }}
            key={props.name}
            {...props}
        />
    );
};

export default AsyncSelect;

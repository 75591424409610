import {
    keyBy,
    groupBy,
    map,
    flow,
    orderBy,
    filter,
    slice,
    get,
} from 'lodash/fp';
import { createSelector } from 'reselect';

const initialState = {
    skillsByName: undefined,
    occupationsByCode: undefined,
    skillsByOccupationCode: undefined,
    levelByOccupationCode: undefined,
    coreSkillsByOccupationCode: undefined,
    technologyToolsByOccupationCode: undefined,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_OCCUPATIONS_SUCCESS':
            const occupationsByCode = flow(
                map(x => ({
                    ...x,
                    id: x['ANZSCO_MAPPED_CODE'],
                    label: x['ANZSCO_MAPPED_TITLE'],
                    type: 'occupation',
                    yearlyWage: x.WAGE * 52,
                    growthRate: x.GROWTH_RATE,
                })),
                keyBy('ANZSCO_MAPPED_CODE')
            )(action.payload);

            return {
                ...state,
                occupationsByCode,
            };
        case 'FETCH_SKILLS_SUCCESS':
            const skills = map(
                x => ({
                    ...x,
                    id: x['DWA_TITLE'] + x.ANZSCO_MAPPED_CODE,
                    label: x['DWA_TITLE'],
                    type: 'skill',
                    hoursPerWeek: x.HOURS_PER_DAY * 5,
                }),
                action.payload
            );
            return {
                ...state,
                skillsByOccupationCode: groupBy('ANZSCO_MAPPED_CODE', skills),
                skillsByName: groupBy('DWA_TITLE', skills),
            };
        case 'FETCH_SIMILARITY_SCORES_SUCCESS':
            const similarityScores = action.payload.reduce((acc, val) => {
                const { OCC_S, OCC_T, SIMILARITY_SCORE } = val;
                acc.set(`${OCC_S}-${OCC_T}`, SIMILARITY_SCORE);
                return acc;
            }, new Map());
            return {
                ...state,
                similarityScores,
            };
        case 'FETCH_SKILL_LEVELS_SUCCESS':
            return {
                ...state,
                levelByOccupationCode: keyBy('ANZSCO_MAPPED', action.payload),
            };
        case 'FETCH_CORE_SKILLS_SUCCESS':
            return {
                ...state,
                coreSkillsByOccupationCode: groupBy(
                    'ANZSCO_MAPPED_CODE',
                    action.payload
                ),
            };
        case 'FETCH_TECHNOLOGY_TOOLS_SUCCESS':
            return {
                ...state,
                technologyToolsByOccupationCode: flow(
                    map(x => ({ ...x, label: x.TECHNOLOGY_TOOL })),
                    groupBy('ANZSCO_MAPPED_CODE')
                )(action.payload),
            };

        default:
            return state;
    }
};

const selectAllOccupations = s => Object.values(s.data.occupationsByCode || {});

export const selectOccupationRelatedToOccupation = createSelector(
    [
        selectAllOccupations,
        s => s.data.similarityScores,
        s => s.ui.alternateTitleCurrentOccupation,
        s => s.ui.alternateTitlePastOccupations,
        s => s.ui.futureOccupations,
        (state, relatedOccupation) => relatedOccupation,
    ],
    (
        occupations,
        similarityScores,
        alternateTitleCurrentOccupation,
        alternateTitlePastOccupations,
        futureOccupations,
        relatedOccupationId
    ) => {
        if (!relatedOccupationId) return [];
        const pastJobCodes = alternateTitlePastOccupations.map(
            x => x.ANZSCO_MAPPED_CODE
        );
        const jobsToIgnore = [
            get('ANZSCO_MAPPED_CODE', alternateTitleCurrentOccupation),
        ]
            .concat(pastJobCodes.concat(futureOccupations))
            .filter(Boolean);

        const filtered = occupations.reduce((acc, o) => {
            if (relatedOccupationId === o.id) return acc;
            const similarityScore =
                similarityScores.get(`${relatedOccupationId}-${o.id}`);
            if (similarityScore) {
                return acc.concat({ ...o, similarityScore });
            }
            return acc;
        }, []);

        return flow(
            orderBy('similarityScore', 'desc'),
            filter(x => !jobsToIgnore.includes(x.id)),
            slice(0, 20)
        )(filtered);
    }
);

const fetchJson = (...args) => {
    return fetch(args).then(x => x.json());
};

const createThunk = ({ actions, url }) => () => {
    return dispatch => {
        dispatch({ type: actions.request });
        fetchJson(url).then(json => {
            dispatch({ type: actions.success, payload: json });
        });
    };
};

export const fetchSkillLevels = createThunk({
    actions: {
        request: 'FETCH_SKILL_LEVELS_REQUEST',
        success: 'FETCH_SKILL_LEVELS_SUCCESS',
    },
    url: '/data/skill-level.json',
});
export const fetchOccupation = createThunk({
    actions: {
        request: 'FETCH_OCCUPATIONS_REQUEST',
        success: 'FETCH_OCCUPATIONS_SUCCESS',
    },
    url: '/data/occupations.json',
});
export const fetchSkills = createThunk({
    actions: {
        request: 'FETCH_SKILLS_REQUEST',
        success: 'FETCH_SKILLS_SUCCESS',
    },
    url: '/data/skills.json',
});
export const fetchSimilarity = createThunk({
    actions: {
        request: 'FETCH_SIMILARITY_SCORES_REQUEST',
        success: 'FETCH_SIMILARITY_SCORES_SUCCESS',
    },
    url: '/data/similarity-scores.json',
});
export const fetchCoreSkills = createThunk({
    actions: {
        request: 'FETCH_CORE_SKILLS_REQUEST',
        success: 'FETCH_CORE_SKILLS_SUCCESS',
    },
    url: '/data/core-skills.json',
});
export const fetchTechnologyTools = createThunk({
    actions: {
        request: 'FETCH_TECHNOLOGY_TOOLS_REQUEST',
        success: 'FETCH_TECHNOLOGY_TOOLS_SUCCESS',
    },
    url: '/data/technology-tools.json',
});

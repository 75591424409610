export default function dontGoInTheRectangles(
    _rects,
    _nodeRadius = 5,
    _padding = 20
) {
    var nodes;
    var strength = 0.08;
    var rectangles = [];
    var padding = _padding;
    var nodeRadius = _nodeRadius;
    if (_rects) {
        rectangles = _rects;
    }

    function force() {
        var i;
        var n = nodes.length;
        var node;
        rectangles.forEach(rectangle => {
            const rectCenter = [
                (rectangle[0][0] + rectangle[1][0]) / 2,
                (rectangle[0][1] + rectangle[1][1]) / 2,
            ];

            for (i = 0; i < n; ++i) {
                node = nodes[i];

                const rectWidth = rectangle[1][0] - rectangle[0][0];
                const rectHeight = rectangle[1][1] - rectangle[0][1];
                const shortestDistanceX =
                    Math.abs(rectCenter[0] - node.x) -
                    (rectWidth + nodeRadius * 2) / 2;
                const shortestDistanceY =
                    Math.abs(rectCenter[1] - node.y) -
                    (rectHeight + nodeRadius * 2) / 2;
                const affectedDistance = padding;

                const vec = [node.x - rectCenter[0], node.y - rectCenter[1]];
                const mag = Math.max(shortestDistanceX, shortestDistanceY);

                if (mag < affectedDistance) {
                    const newForceStrength =
                        ((affectedDistance - mag) / affectedDistance) *
                        strength;

                    node.vx += vec[0] * newForceStrength;
                    node.vy += vec[1] * newForceStrength;
                }
            }
        });
    }

    force.initialize = function(_nodes) {
        nodes = _nodes;
    };

    force.strength = function(_) {
        return arguments.length ? ((strength = +_), force) : strength;
    };

    force.rectangle = function(_rect) {
        return (rectangles = _rect);
    };

    return force;
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select';
import {
    setAlternateTitlePastOccupation,
    resetState,
    setAlternateTitleCurrentOccupation,
} from '../ducks/ui';
import OccupationSelect from '../component/occupation-select';
import LandingForceVis from '../component/landing-force-vis';
import LandingPageLegend from '../component/landing-page-legend';
import { colors } from '../modules/theme';
const currentOccupationWorker = new Worker(
    '../modules/worker-filter-options.js',
    { type: 'module' }
);
const pastOccupationsWorker = new Worker(
    '../modules/worker-filter-options.js',
    { type: 'module' }
);

const alternateTitleToOption = x =>
    x
        ? {
              value: x,
              label: `${x.ANZSCO_MAPPED_TITLE} (${x.ALTERNATE_TITLES})`,
          }
        : null;

function CareerPathLanding({
    previousOccupations,
    currentOccupation,
    setAlternateTitlePastOccupation,
    setAlternateTitleCurrentOccupation,
    match,
    history,
    resetState,
}) {
    // We keep this in localstate sincewe need the alternate title.
    const [alternateOccupationTitles, setAlternateOccupationTitles] = useState(
        []
    );
    useEffect(() => {
        if (!match.params.alternateOccupationId) {
            setAlternateTitleCurrentOccupation();
        }
    }, [
        match.params.alternateOccupationId,
        setAlternateTitleCurrentOccupation,
    ]);

    useEffect(() => {
        fetch('/data/alternate-titles.json')
            .then(x => x.json())
            .then(data => {
                setAlternateOccupationTitles(data);
                const options = data.map(alternateTitleToOption);
                currentOccupationWorker.postMessage({
                    type: 'STORE_OPTIONS',
                    payload: options,
                });
                pastOccupationsWorker.postMessage({
                    type: 'STORE_OPTIONS',
                    payload: options,
                });
            });
    }, [setAlternateTitleCurrentOccupation]);

    useEffect(() => {
        if (
            alternateOccupationTitles &&
            match.params.alternateOccupationId &&
            !currentOccupation
        ) {
            const currentMatchedJob = alternateOccupationTitles.find(
                x => x.id === parseInt(match.params.alternateOccupationId)
            );
            setAlternateTitleCurrentOccupation(currentMatchedJob);
        }
    }, [
        alternateOccupationTitles,
        currentOccupation,
        match.params.alternateOccupationId,
        setAlternateTitleCurrentOccupation,
    ]);
    const Input = props => {
        return (
            <components.Input
                {...props}
                disabled={previousOccupations.length >= 3}
            />
        );
    };
    const Menu = props => {
        if (previousOccupations.length >= 3) return null;
        return <components.Menu {...props}>{props.children}</components.Menu>;
    };

    return (
        <div>
            <div css={{ display: 'flex', justifyContent: 'space-between' }}>
                <div css={{ flexBasis: '49%' }}>
                    <p>My recent job is</p>
                    <OccupationSelect
                        worker={currentOccupationWorker}
                        name={'current'}
                        isClearable={true}
                        value={alternateTitleToOption(currentOccupation)}
                        onChange={val => {
                            if (!val) {
                                history.push('/career-path');
                                return setAlternateTitleCurrentOccupation();
                            }
                            setAlternateTitleCurrentOccupation(val.value);

                            history.replace('/career-path/' + val.value.id);
                        }}
                        placeholder="Select occupation"
                        noOptionsMessage={({ inputValue }) => {
                            if (inputValue.length < 3) {
                                return 'Type at least 3 characters to search';
                            }
                            return 'No Options';
                        }}
                    />
                </div>
                <div css={{ flexBasis: '49%' }}>
                    <p>I have past experience as a</p>
                    <OccupationSelect
                        worker={pastOccupationsWorker}
                        name={'past'}
                        isDisabled={!Boolean(currentOccupation)}
                        components={{ Input, Menu }}
                        placeholder="Select up to 3 occupations"
                        isMulti={true}
                        noOptionsMessage={({ inputValue }) => {
                            if (previousOccupations.length >= 3) {
                                return 'You are only allowed to add 3 past jobs';
                            }
                            if (inputValue.length < 3) {
                                return 'Type at least 3 characters to search';
                            }
                            return 'No Options';
                        }}
                        value={previousOccupations.map(alternateTitleToOption)}
                        onChange={(val, x, y) => {
                            if (val === null) {
                                setAlternateTitlePastOccupation([]);
                            } else {
                                setAlternateTitlePastOccupation(
                                    val.map(x => x.value)
                                );
                            }
                        }}
                    />
                </div>
            </div>
            <div
                css={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 10,
                }}
            >
                <button
                    css={{
                        border: 'none',
                        textDecoration: 'underline',
                        color: colors.darkBlue,
                        cursor: 'pointer',
                    }}
                    onClick={e => {
                        history.push('/career-path');
                        resetState();
                    }}
                >
                    Reset
                </button>
            </div>
            {currentOccupation && (
                <>
                    <LandingForceVis match={match} history={history} />
                    <LandingPageLegend />
                </>
            )}
        </div>
    );
}

export default connect(
    s => ({
        occupationsByCode: s.data.occupationsByCode,
        showingOccupationCodes: s.ui.showingOccupationCodes,
        previousOccupations: s.ui.alternateTitlePastOccupations,
        currentOccupation: s.ui.alternateTitleCurrentOccupation,
    }),
    {
        setAlternateTitlePastOccupation,
        setAlternateTitleCurrentOccupation,
        resetState,
    }
)(CareerPathLanding);

import React, { useRef, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { selectOccupationRelatedToOccupation } from '../../ducks/data';
import { flatMap, uniqBy, get, keyBy } from 'lodash/fp';
import {
    forceSimulation,
    forceLink,
    forceCollide,
    forceManyBody,
    forceRadial,
} from 'd3-force';
import { scaleLinear, scaleLog } from 'd3-scale';
import { extent } from 'd3-array';
import { select } from 'd3-selection';
import { colors } from '../../modules/theme';
import dontGoInTheRectangles from '../../modules/dont-go-in-the-rectangles-force';
import CurrentOccupationTitle from './current-occupation-title';
import Popup from './popup';
import { attachPretendOpacity } from '../../modules/attachPretendOpacity';
import dragDrop from '../../modules/drag-drop';
import PositiveGrowthToggle from '../PositiveGrowthToggle';
import { event } from 'd3-selection';

export const width = 900;
const height = 800;
const currentOccupationSize = 30;
const skillSize = 3;
const innerCircleSize = 160;
const radialPadding = 15;
const radialScale = scaleLog().range([0.05, 0.9]);
const circleStrokeSize = 2;
const randomYPosScale = scaleLinear()
    .range([0, height])
    .domain([0, 1]);
const randomXPosScale = scaleLinear()
    .range([0, width])
    .domain([0, 1]);

const occupationScale = scaleLinear().range([5, currentOccupationSize]);

const rectToNotGoInto = [
    [width / 2 - innerCircleSize, height / 2 + (currentOccupationSize + 80)],
    [width / 2 + innerCircleSize, height / 2 + (currentOccupationSize + 190)],
];
const getData = (occupations, currentOccupation, skills) => {
    const currentWithPos = {
        ...currentOccupation,
        fx: width / 2,
        fy: height / 2,
    };
    const relatedOccupations = occupations.map(occupation => {
        if (occupation.x) return occupation;
        return {
            ...occupation,
            x: randomXPosScale(Math.random()),
            y: randomYPosScale(Math.random()),
        };
    });
    const skillsWithPos = skills.map(skill => {
        if (skill.x) return skill;
        return {
            ...skill,
            type: 'skill',
            x: width / 2,
            y: height / 2,
        };
    });

    return {
        nodes: [currentWithPos]
            .concat(relatedOccupations)
            .concat(skillsWithPos),
        links: relatedOccupations.map(occupation => {
            return {
                target: currentWithPos,
                source: occupation,
            };
        }),
    };
};

const useSimulation = (currentOccupation, skills) => {
    return useMemo(() => {
        const simulationSetup = forceSimulation()
            .force(
                'link',
                forceLink()
                    .id(function(node) {
                        return node.id;
                    })
                    .distance(x => innerCircleSize)
            )
            .force('charge', forceManyBody().strength(-100))
            .force(
                'rect1',
                dontGoInTheRectangles([rectToNotGoInto], skillSize, 1)
            )
            .force(
                'radial',
                forceRadial(d => {
                    if (d.type === 'skill') return currentOccupationSize + 5;

                    return (
                        innerCircleSize +
                        occupationScale.range()[1] +
                        radialPadding
                    );
                })
                    .x(width / 2)
                    .y(height / 2)
                    .strength(d => {
                        if (d.type === 'skill') return 1;
                        return radialScale(d.similarityScore);
                    })
            )
            .force(
                'collisionForce',
                forceCollide()
                    .strength(2)
                    .iterations(100)
                    .radius(d => {
                        if (d.id === currentOccupation.id) {
                            return innerCircleSize;
                        }
                        if (d.type === 'skill') return skillSize;

                        const size = occupationScale(d.similarityScore);
                        const padding = 5;
                        return size + padding;
                    })
            );
        return simulationSetup;
        // please reevaluate when number of skills change
        // eslint-disable-next-line
    }, [currentOccupation.id, skills]);
};

const getRelatedSkills = (
    currentOccupation,
    hoveredCircle,
    skillsByOccupationCode,
    skills
) => {
    if (!hoveredCircle) return [];
    if (hoveredCircle.type === 'skill') return [];
    if (hoveredCircle.id === currentOccupation.id) return [];

    const skillsByLabel = keyBy(
        'label',
        skillsByOccupationCode[hoveredCircle.ANZSCO_MAPPED_CODE]
    );
    return skills.filter(x => skillsByLabel[x.label]);
};

const getRelatedOccupations = (
    occupations,
    hoveredCircle,
    skillsByOccupationCode
) => {
    if (!hoveredCircle) return [];
    if (hoveredCircle.type === 'occupation') return [];
    return keyBy(
        'id',
        occupations.filter(occupation => {
            const skills = skillsByOccupationCode[occupation.id];
            return skills.find(skill => skill.label === hoveredCircle.label);
        })
    );
};

function LandingForceVis({
    occupations,
    currentOccupation,
    skillsByOccupationCode,
    skills,
    history,
    match,
    previousOccupations,
}) {
    const nodesRef = useRef();
    const linksRef = useRef();
    const [hoveredCircle, setHoveredCircle] = useState();
    const [onlyShowPositiveGrowth, setOnlyShowPositiveGrowth] = useState(false);
    const similarityExtent = extent(occupations, d => d.similarityScore);
    occupationScale.domain(similarityExtent);
    radialScale.domain(similarityExtent);
    const relatedSkills = getRelatedSkills(
        currentOccupation,
        hoveredCircle,
        skillsByOccupationCode,
        skills
    );
    const relatedOccupationsByCode = getRelatedOccupations(
        occupations,
        hoveredCircle,
        skillsByOccupationCode
    );
    const data = useMemo(() => {
        return getData(occupations, currentOccupation, skills);
    }, [occupations, currentOccupation, skills]);
    const simulation = useSimulation(currentOccupation, skills);

    useEffect(() => {
        if (simulation) {
            const linkContainer = select(linksRef.current);
            const nodesContainer = select(nodesRef.current);

            var linkSelection = linkContainer
                .selectAll('line')
                .data(data.links);
            linkSelection.exit().remove();
            linkSelection = linkSelection
                .enter()
                .append('line')
                .attr('stroke-width', 2)
                .attr('stroke-dasharray', 4)
                .attr('stroke', colors.lightBlue)
                .attr('opacity', 0.5)
                .merge(linkSelection)
                .style('display', d => {
                    if (d.source.growthRate < 0 && onlyShowPositiveGrowth) {
                        return 'none';
                    }
                })
                .attr('stroke', d => {
                    if (!hoveredCircle) return colors.lightBlue;
                    if (hoveredCircle.id === d.source.id)
                        return colors.lightBlue;
                    if (hoveredCircle.id === d.target.id)
                        return colors.lightBlue;
                    if (relatedOccupationsByCode[d.target.id]) {
                        return colors.lightBlue;
                    }
                    if (relatedOccupationsByCode[d.source.id]) {
                        return colors.lightBlue;
                    }
                    return attachPretendOpacity(colors.lightBlue, 0.2);
                });
            var nodeSelection = nodesContainer.selectAll('g').data(data.nodes);

            nodeSelection.exit().remove();

            nodeSelection = nodeSelection
                .enter()
                .append('g')
                .merge(nodeSelection)
                .style('display', d => {
                    if (d.growthRate < 0 && onlyShowPositiveGrowth) {
                        return 'none';
                    }
                })
                .attr('data-label', d => d.label)
                .attr('class', d => {
                    // TODO I have the class but I havent figured our how to move the elements without breaking
                    if (relatedOccupationsByCode[d.id]) {
                        return `${d.type} raise-me`;
                    }
                    return d.type;
                });

            let textSelection = nodeSelection
                .filter(d => d.type === 'occupation')

                .selectAll('text')
                .data(d => [d]);
            textSelection.exit().remove();

            textSelection = textSelection
                .enter()
                .append('text')
                .style('pointer-events', 'none')
                .attr('font-size', 10)
                .merge(textSelection)
                .attr('opacity', d => {
                    if (!hoveredCircle) return 1;
                    if (hoveredCircle.id === d.id) {
                        return 1;
                    }
                    if (relatedOccupationsByCode[d.id]) {
                        return 1;
                    }
                    return 0.2;
                })
                .text(d => (d.type !== 'skill' ? d.label : ''))
                .attr('text-anchor', 'middle')
                .attr('transform', d => {
                    if (d.type === 'skill') return;
                    const textSize = 12;
                    return `translate(0 ${occupationScale(d.similarityScore) +
                        textSize})`;
                });

            let outerCircleSelection = nodeSelection
                // .filter(d => d.type === 'occupation')
                .selectAll('.outer-circle')
                .data(d => [d]);
            outerCircleSelection.exit().remove();

            outerCircleSelection = outerCircleSelection
                .enter()
                .append('circle')
                .attr('class', 'outer-circle')
                .merge(outerCircleSelection)
                .attr('stroke', d => {
                    if (!hoveredCircle || hoveredCircle.id === d.id) {
                        return colors.blue;
                    }
                    if (relatedOccupationsByCode[d.id]) {
                        return colors.blue;
                    }
                    return attachPretendOpacity(colors.blue, 0.2);
                })
                .style('cursor', d => {
                    if (d.type === 'skill') return 'auto';
                    return 'pointer';
                })
                .attr('r', d => {
                    if (d.type === 'skill') return skillSize;
                    return occupationScale(d.similarityScore);
                })
                .attr('fill', d => {
                    if (d.type === 'occupation') {
                        return colors.whiteBlue;
                    }

                    if (get('id', hoveredCircle) === d.id) {
                        return colors.lightBlue;
                    }
                    if (
                        relatedSkills.find(related => related.label === d.label)
                    ) {
                        return colors.blue;
                    }
                    return 'white';
                })
                .on('mouseover', d => {
                    event.stopPropagation();
                    if (d.id === get('id', hoveredCircle)) return;
                    setHoveredCircle(d);
                })

                .on('click', d => {
                    if (d.type === 'skill') return;
                    history.push(
                        `/career-path/${match.params.alternateOccupationId}/occupations/${d.id}`
                    );
                })
                .call(dragDrop(simulation));

            let innerCircleSelection = nodeSelection
                .filter(d => d.type === 'occupation')
                .selectAll('.inner-circle')
                .data(d => [d]);

            innerCircleSelection.exit().remove();

            innerCircleSelection = innerCircleSelection
                .enter()
                .append('circle')
                .attr('class', 'inner-circle')
                .merge(innerCircleSelection)
                .style('cursor', 'pointer')
                .attr('fill', d => {
                    if (relatedOccupationsByCode[d.id]) {
                        return colors.blue;
                    }
                    if (!hoveredCircle || hoveredCircle.id === d.id) {
                        return colors.lightBlue;
                    }
                    return attachPretendOpacity(colors.lightBlue, 0.2);
                })
                .on('mouseover', d => {
                    if (d.id === currentOccupation.id) return;
                    event.stopPropagation();

                    if (d.id === get('id', hoveredCircle)) return;
                    setHoveredCircle(d);
                })
                .on('click', d => {
                    history.push(
                        `/career-path/${match.params.alternateOccupationId}/occupations/${d.id}`
                    );
                })
                .call(dragDrop(simulation))
                .attr('r', d => {
                    return occupationScale(d.similarityScore) * 0.8;
                });

            simulation.nodes(data.nodes).on('tick', () => {
                // These x and y functions make sure the circles never goes outside the svg
                // inspo https://bl.ocks.org/mbostock/1129492
                const x = d => {
                    const radius = occupationScale(d.similarityScore) || 0;
                    return (d.x = Math.max(
                        radius,
                        Math.min(width - radius, d.x)
                    ));
                };
                const y = d => {
                    const radius = occupationScale(d.similarityScore) || 0;
                    return (d.y = Math.max(
                        radius,
                        Math.min(height - radius, d.y)
                    ));
                };
                textSelection.attr('x', x).attr('y', y);
                innerCircleSelection.attr('cx', x).attr('cy', y);
                outerCircleSelection.attr('cx', x).attr('cy', y);
                linkSelection
                    .attr('x1', get(['source', 'x']))
                    .attr('y1', get(['source', 'y']))
                    .attr('x2', get(['target', 'x']))
                    .attr('y2', get(['target', 'y']))
                    .attr('id', get(['target', 'id']));
            });
        }
    }, [
        currentOccupation.id,
        data.links,
        data.nodes,
        history,
        hoveredCircle,
        match.params.alternateOccupationId,
        onlyShowPositiveGrowth,
        relatedOccupationsByCode,
        relatedSkills,
        simulation,
    ]);

    return (
        <div>
            <div
                css={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: 10,
                }}
            >
                <PositiveGrowthToggle
                    onlyShowPositiveGrowth={onlyShowPositiveGrowth}
                    onChange={setOnlyShowPositiveGrowth}
                />
            </div>
            <h2 css={{ textAlign: 'center' }}>I want my future job to be</h2>
            <div
                css={{ position: 'relative', width, height, margin: '0 auto' }}
            >
                <svg
                    css={{ margin: '0 auto', display: 'block' }}
                    width={width}
                    height={height}
                    onMouseOver={e => {
                        if (hoveredCircle) {
                            setHoveredCircle(null);
                        }
                    }}
                >
                    <pattern
                        id="diagonalHatch"
                        patternUnits="userSpaceOnUse"
                        width="4"
                        height="4"
                    >
                        <path d="M-1 1l2-2M0 4l4-4M3 5l2-2" stroke="#7b93b6" />
                    </pattern>
                    <g className="links" ref={linksRef} />

                    <circle
                        stroke={colors.veryLightBlue}
                        strokeWidth="2"
                        fill="white"
                        cx={width / 2}
                        cy={height / 2}
                        r={innerCircleSize}
                    />
                    {previousOccupations.map((circle, i, arr) => {
                        const rotation =
                            arr.length === 1 ? ['180'] : ['135', '225', '180'];
                        const radius = 8;
                        return (
                            <circle
                                fill="url(#diagonalHatch)"
                                key={circle.id}
                                stroke={colors.darkBlue}
                                transform={`rotate(${rotation[i]}, ${width /
                                    2},${height /
                                    2}) translate(0 ${currentOccupationSize +
                                    (radius - 3)})`}
                                cy={height / 2}
                                cx={width / 2}
                                r={radius}
                            />
                        );
                    })}
                    <CurrentOccupationTitle
                        textAnchor="middle"
                        fontSize={11}
                        fontWeight={'bold'}
                        x={width / 2}
                        y={rectToNotGoInto[0][1]}
                        text={currentOccupation.ANZSCO_MAPPED_TITLE}
                    />

                    {/* <rect
                x={rectToNotGoInto[0][0]}
                y={rectToNotGoInto[0][1]}
                width={rectToNotGoInto[1][0] - rectToNotGoInto[0][0]}
                height={rectToNotGoInto[1][1] - rectToNotGoInto[0][1]}
                fill="black"
            /> */}
                    <g className="nodes" ref={nodesRef} />
                    <circle
                        className="current-occupation-circle"
                        stroke={colors.whiteBlue}
                        strokeWidth={7}
                        cy={height / 2}
                        cx={width / 2}
                        r={currentOccupationSize}
                        fill={colors.blue}
                    />
                </svg>
                {hoveredCircle && (
                    <Popup
                        numberOfRelatedSkills={relatedSkills.length}
                        hoveredCircle={hoveredCircle}
                        top={
                            hoveredCircle.y +
                            (occupationScale(hoveredCircle.similarityScore) ||
                                skillSize) +
                            circleStrokeSize
                        }
                        left={hoveredCircle.x}
                    />
                )}
            </div>
        </div>
    );
}

export default connect((state, props) => {
    const currentOccupation = state.ui.alternateTitleCurrentOccupation;

    const previousOccupations = state.ui.alternateTitlePastOccupations;
    const skillsByOccupationCode = state.data.skillsByOccupationCode;
    const prevOccupationsCodes = state.ui.alternateTitlePastOccupations.map(
        x => x.ANZSCO_MAPPED_CODE
    );
    const skills = uniqBy(
        'label',
        flatMap(
            code => skillsByOccupationCode[code],
            prevOccupationsCodes
                .concat(currentOccupation.ANZSCO_MAPPED_CODE)
                .concat(previousOccupations.map(x => x.ANZSCO_MAPPED_CODE))
        )
    );

    return {
        previousOccupations,
        occupations: selectOccupationRelatedToOccupation(
            state,
            currentOccupation.ANZSCO_MAPPED_CODE
        ),
        skills,
        prevOccupationsCodes,
        currentOccupation,
        skillsByOccupationCode,
    };
})(LandingForceVis);

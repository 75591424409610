import React, { useEffect } from 'react';
import CareerPathPathway from './pages/career-path-pathway';
import CareerPathLanding from './pages/career-path-landing';
import CareerPathOccupation from './pages/career-path-occupation';
import LoadingAnimation from './component/loading';
import {
    BrowserRouter as Router,
    Route,
    NavLink,
    Switch,
    Redirect,
} from 'react-router-dom';
import { fetchOccupation, fetchSkills, fetchSimilarity } from './ducks/data';
import { connect } from 'react-redux';
import { colors } from './modules/theme';
import Occupations from './pages/occupations';
import Occupation from './pages/occupation';
import { setAlternateTitlePastOccupation } from './ducks/ui';

const NavLinkStyles = {
    color: colors.darkBlue,
    opacity: 0.5,
    marginRight: 10,
    textDecoration: 'none',
};

const NavActiveStyles = {
    opacity: 1,
    borderBottom: `2.5px solid ${colors.darkBlue}`,
};

function App(props) {
    const {
        fetchOccupation,
        fetchSkills,
        fetchSimilarity,
        setAlternateTitlePastOccupation,
    } = props;
    useEffect(() => {
        fetchOccupation();
        fetchSkills();
        fetchSimilarity();
    }, [fetchOccupation, fetchSimilarity, fetchSkills]);

    return (
        // The Career path vis is 875 + 500 when it has 3 occupation circles + add exanded
        <div css={{ width: 1325, margin: '0 auto' }}>
            <h1>Skills</h1>
            <Router>
                <div
                    css={{
                        background: colors.whiteBlue,
                        height: 80,
                        position: 'absolute',
                        width: '100%',
                        minWidth: 1325,
                        left: 0,
                        zIndex: -1,
                    }}
                />
                <nav
                    css={{
                        height: 80,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <NavLink
                        to="/career-path"
                        css={NavLinkStyles}
                        onClick={() => {
                            setAlternateTitlePastOccupation([]);
                        }}
                        activeStyle={NavActiveStyles}
                    >
                        My Career Path
                    </NavLink>
                    <NavLink
                        to="/occupations"
                        css={NavLinkStyles}
                        activeStyle={NavActiveStyles}
                    >
                        Look up a job
                    </NavLink>
                </nav>

                {!props.skillsByName || !props.occupationsByCode ? (
                    <LoadingAnimation />
                ) : (
                    <Switch>
                        <Route
                            path="/"
                            render={props => <Redirect to={'/career-path'} />}
                            exact
                        />
                        <Route
                            path="/career-path/:alternateOccupationId?"
                            component={CareerPathLanding}
                            exact
                        />
                        <Route
                            path="/career-path/:alternateOccupationId/occupations/:occupationCode"
                            component={CareerPathOccupation}
                            exact
                        />
                        <Route
                            path="/career-path/:alternateOccupationId/vis/:occopationCode"
                            component={CareerPathPathway}
                            exact
                        />
                        <Route
                            path="/occupations/"
                            component={Occupations}
                            exact
                        />
                        <Route
                            path="/occupations/:occupationCode"
                            component={Occupation}
                            exact
                        />
                    </Switch>
                )}
            </Router>
        </div>
    );
}

export default connect(
    state => ({
        occupationsByCode: state.data.occupationsByCode,
        skillsByName: state.data.skillsByName,
        skillsByOccupationCode: state.data.skillsByOccupationCode,
    }),
    {
        fetchOccupation,
        fetchSkills,
        fetchSimilarity,
        setAlternateTitlePastOccupation,
    }
)(App);

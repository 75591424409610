import React, { useEffect, useState } from 'react';
import BackButton from '../../component/back-button';
import InfoSection from '../../component/occupation-info-section';
import { connect } from 'react-redux';
import {
    fetchSkillLevels,
    fetchCoreSkills,
    fetchTechnologyTools,
} from '../../ducks/data';
import LoadingAnimation from '../../component/loading';
import { colors } from '../../modules/theme';
import CoreSkillBarChart from '../../component/core-skill-bar-chart';
import { scale as techSkillsScale } from '../../modules/skill-circle';
import { extent } from 'd3-array';
import { orderBy, get } from 'lodash/fp';
import ArrowUp from '../../component/ArrowUp';
import TechnicalSkillsList from '../../component/technical-skills-list';
import TechnologyToolsList from '../../component/technology-tools-list';
import OccupationPopup from './occupation-popup';

function Occupation({
    match,
    occupation,
    coreSkillsByOccupationCode,
    fetchCoreSkills,
    fetchSkillLevels,
    fetchTechnologyTools,
    skills,
    technologyTools,
}) {
    useEffect(() => {
        fetchSkillLevels();
        fetchCoreSkills();
        fetchTechnologyTools();
    }, [fetchCoreSkills, fetchSkillLevels, fetchTechnologyTools]);
    const [showPopup, setShowPopup] = useState(false);
    if (!coreSkillsByOccupationCode || !technologyTools)
        return <LoadingAnimation />;

    techSkillsScale.domain(extent(skills, x => x.hoursPerWeek));

    return (
        <div>
            {showPopup && (
                <div
                    onClick={() => setShowPopup(false)}
                    css={{
                        cursor: 'pointer',
                        position: 'fixed',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: 'rgba(255,255,255,0.8)',
                        zIndex: 40,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <OccupationPopup
                        match={match}
                        onCloseClick={() => setShowPopup(false)}
                    />
                </div>
            )}
            <BackButton to={`/occupations`} />
            <div
                css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <h2>{occupation.label}</h2>
                <button
                    onClick={e => setShowPopup(show => !show)}
                    css={{
                        border: `2px solid ${colors.darkBlue}`,
                        color: colors.darkBlue,
                        padding: '10px 12px',
                        flexBasis: 250,
                        fontSize: '14px',
                        textDecoration: 'none',
                        textAlign: 'center',
                        fontWeight: 'bold',
                    }}
                >
                    View similar jobs
                </button>
            </div>
            <InfoSection occupation={occupation} />
            <div css={{ display: 'flex', alignItems: 'flex-start' }}>
                <div>
                    <div
                        css={{
                            border: '1px solid #EDF2F7',
                            borderRadius: '4px',
                            padding: '10px 30px',
                            width: 375,
                        }}
                    >
                        <h3>Core competencies</h3>
                        <p>
                            Set of non-technical tasks, knowledge and
                            understandings that underpin successful
                            participation in work.
                        </p>
                        <ul css={{ background: colors.whiteBlue, padding: 16 }}>
                            {orderBy(
                                'IMPORTANCE',
                                'desc',
                                coreSkillsByOccupationCode[occupation.id]
                            ).map((skill, i) => {
                                return (
                                    <li key={i}>
                                        <CoreSkillBarChart
                                            showOccupationLabels={false}
                                            coreSkillsByOccupationCode={
                                                coreSkillsByOccupationCode
                                            }
                                            skill={skill}
                                            occupation={occupation}
                                            barColor={colors.lightBlue}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div
                        css={{
                            border: '1px solid #EDF2F7',
                            borderRadius: '4px',
                            padding: '10px 30px',
                            width: 375,
                            marginTop: 30,
                        }}
                    >
                        <h3>Technology tools</h3>
                        <p>
                            Software or software tools associated with this
                            occupation.
                        </p>
                        <TechnologyToolsList tools={technologyTools} />
                    </div>
                </div>
                <div
                    css={{
                        flexGrow: 1,
                        background: colors.whiteBlue,
                        padding: '10px 30px',
                        marginLeft: '30px',
                        borderRadius: '4px',
                    }}
                >
                    <h3>Technical tasks</h3>
                    <p>Detailed work activities required within the job.</p>
                    <div css={{ display: 'flex' }}>
                        <ArrowUp css={{ paddingTop: 35 }} />
                        <TechnicalSkillsList
                            border={false}
                            scale={techSkillsScale}
                            skillType={'generic'}
                            skills={skills}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(
    (state, props) => {
        const {
            technologyToolsByOccupationCode,
            skillsByOccupationCode,
            occupationsByCode,
        } = state.data;
        const code = props.match.params.occupationCode;
        const skills = orderBy(
            'hoursPerWeek',
            'desc',
            skillsByOccupationCode[code]
        );

        return {
            occupation: occupationsByCode[code],
            coreSkillsByOccupationCode: state.data.coreSkillsByOccupationCode,
            technologyTools: get(code, technologyToolsByOccupationCode),
            skills,
        };
    },
    {
        fetchSkillLevels,
        fetchCoreSkills,
        fetchTechnologyTools,
    }
)(Occupation);

import React, { useMemo, useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { selectOccupationRelatedToOccupation } from '../../ducks/data';
import { addFutureOccupationCode } from '../../ducks/ui';
import { last, get, keyBy, uniqBy, flatMap } from 'lodash/fp';
import {
    forceSimulation,
    forceCollide,
    forceManyBody,
    forceRadial,
    forceX,
    forceY,
} from 'd3-force';

import { scaleLinear, scaleLog } from 'd3-scale';
import { extent } from 'd3-array';
import { select } from 'd3-selection';
import Popup from './popup';
import { colors } from '../../modules/theme';
import dragDrop from '../../modules/drag-drop';
import { handleLeave } from '../../modules/handleLeave';

const middleCircleSize = 30;
const occupationScale = scaleLinear().range([5, middleCircleSize * 0.9]);
const radialScale = scaleLog().range([0.05, 0.9]);
const outerStrokeSize = 1;
const width = 500;
const height = 500;
let simulationInstance = forceSimulation();

const getData = (occupations, occupation) => {
    const currentWithPos = {
        ...occupation,
        fx: width / 2,
        fy: height / 2,
    };
    const relatedOccupations = occupations.map(occupation => {
        if (occupation.x) return occupation;
        return {
            ...occupation,
            x: width / 2,
            y: height / 2,
        };
    });
    return {
        nodes: [currentWithPos].concat(relatedOccupations),
        links: relatedOccupations.map(occupation => {
            return {
                target: currentWithPos,
                source: occupation,
            };
        }),
    };
};

const getRelatedSkills = (
    occupation,
    showingOccupationCodes,
    previousOccupationCodes,
    hoveredCircle,
    skillsByOccupationCode
) => {
    if (!hoveredCircle) return [];
    if (hoveredCircle.type === 'skill') return [];
    if (hoveredCircle.id === occupation) return [];

    const currentSkills = uniqBy(
        'label',
        flatMap(
            code => skillsByOccupationCode[code],
            previousOccupationCodes.concat(showingOccupationCodes)
        )
    );
    const skillsByLabel = keyBy(
        'label',
        skillsByOccupationCode[hoveredCircle.ANZSCO_MAPPED_CODE]
    );
    return currentSkills.filter(x => skillsByLabel[x.label]);
};
const useSimulation = showRelatedJobs => {
    return useMemo(() => {
        if (!showRelatedJobs) {
            simulationInstance
                .force('radial', null)
                .force('charge', null)
                .force('collisionForce', null)
                .force('forceX', forceX(width / 2))
                .force('forceY', forceY(height / 2));
        } else {
            simulationInstance
                .force('forceX', null)
                .force('forceY', null)
                .force(
                    'charge',
                    forceManyBody()
                        .strength(-100)
                        .distanceMin(50)
                )
                .force(
                    'radial',
                    forceRadial(d => {
                        const padding = 10;
                        return (
                            middleCircleSize +
                            occupationScale(d.similarityScore) +
                            padding
                        );
                    })
                        .x(width / 2)
                        .y(height / 2)
                        .strength(d => {
                            return radialScale(d.similarityScore);
                        })
                )
                .force(
                    'collisionForce',
                    forceCollide()
                        .strength(1)
                        .iterations(1)
                        .radius(d => {
                            const size = occupationScale(d.similarityScore);
                            const padding = 5;
                            return size + padding;
                        })
                );
        }

        return simulationInstance.alpha(0.8).restart();
    }, [showRelatedJobs]);
};

function AddOccupationVis({
    occupations,
    occupation,
    addFutureOccupationCode,
    skillsByOccupationCode,
    showingOccupationCodes,
    prevOccupationsCodes,
}) {
    const nodesRef = useRef();
    const linksRef = useRef();
    const [showRelatedJobs, setShowRelatedJobs] = useState(false);
    const [hoveredCircle, setHoveredCircle] = useState(false);

    const similarityExtent = extent(occupations, d => d.similarityScore);
    const simulation = useSimulation(showRelatedJobs);
    occupationScale.domain(similarityExtent);
    radialScale.domain(similarityExtent);
    const data = useMemo(() => {
        return getData(occupations, occupation);
    }, [occupations, occupation]);

    useEffect(() => {
        if (simulation) {
            const linkContainer = select(linksRef.current);
            const nodesContainer = select(nodesRef.current);
            var nodeSelection = nodesContainer.selectAll('g').data(data.nodes);
            const handleCircleClick = d => {
                if (d.id === occupation.id) {
                    return;
                }
                //Skip the fold animaton to the carreer path vis is a bit snappier
                nodesContainer.selectAll('*').remove();
                linkContainer.selectAll('*').remove();

                addFutureOccupationCode(d.id);
            };
            nodeSelection.exit().remove();

            nodeSelection = nodeSelection
                .enter()
                .append('g')
                .merge(nodeSelection)
                .attr('data-label', d => d.label);

            var linkSelection = linkContainer
                .selectAll('line')
                .data(data.links);
            linkSelection.exit().remove();
            linkSelection = linkSelection
                .enter()
                .append('line')
                .attr('stroke-width', 2)
                .attr('stroke-dasharray', 4)
                .attr('stroke', colors.lightBlue)
                .attr('opacity', 0.5)
                .merge(linkSelection);

            let circleSelection = nodeSelection
                .selectAll('.outer-circle')
                .data(d => [d]);
            circleSelection.exit().remove();

            circleSelection = circleSelection
                .enter()
                .append('circle')
                .attr('class', 'outer-circle')
                .merge(circleSelection)
                .style('cursor', d => {
                    if (d.id === occupation.id) {
                        return 'auto';
                    }
                    return 'pointer';
                })
                .attr('r', d => {
                    if (d.id === occupation.id) {
                        return middleCircleSize;
                    }
                    return occupationScale(d.similarityScore);
                })
                .attr('fill', d => {
                    return colors.whiteBlue;
                })
                .attr('stroke', colors.lightBlue)
                .call(dragDrop(simulation))
                .on('mouseenter', d => {
                    if (d.id === occupation.id) return;
                    setHoveredCircle(d);
                })
                .on('mouseleave', handleLeave(setHoveredCircle))
                .on('click', handleCircleClick);
            let innerCircleSelection = nodeSelection
                .selectAll('.inner-circle')
                .data(d => [d]);
            innerCircleSelection.exit().remove();

            innerCircleSelection = innerCircleSelection
                .enter()
                .append('circle')
                .attr('class', 'inner-circle')
                .style('cursor', 'pointer')
                .merge(innerCircleSelection)
                .call(dragDrop(simulation))
                .on('mouseenter', d => {
                    if (d.id === occupation.id) return;
                    setHoveredCircle(d);
                })
                .attr('r', d => {
                    if (d.id === occupation.id) {
                        return middleCircleSize;
                    }
                    return occupationScale(d.similarityScore) * 0.8;
                })
                .attr('fill', colors.lightBlue)
                .on('click', handleCircleClick);

            let textSelection = nodeSelection.selectAll('text').data(d => [d]);
            textSelection.exit().remove();

            textSelection = textSelection
                .enter()
                .append('text')
                .style('pointer-events', 'none')
                .attr('font-size', 10)
                .merge(textSelection)
                .attr('opacity', showRelatedJobs ? 1 : 0)
                .text(d => (d.type !== 'skill' ? d.label : ''))
                .attr('text-anchor', 'middle')
                .style('transform', d => {
                    if (d.id === occupation.id) return;
                    const textSize = 12;
                    return `translate(0, ${occupationScale(d.similarityScore) +
                        textSize}px)`;
                });

            simulation.nodes(data.nodes).on('tick', () => {
                // These x and y functions make sure the circles never goes outside the svg
                // inspo https://bl.ocks.org/mbostock/1129492
                const x = d => {
                    const radius =
                        occupationScale(d.similarityScore) || width / 2;
                    return (d.x = Math.max(
                        radius,
                        Math.min(width - radius, d.x)
                    ));
                };
                const y = d => {
                    const radius =
                        occupationScale(d.similarityScore) || height / 2;
                    return (d.y = Math.max(
                        radius,
                        Math.min(height - radius, d.y)
                    ));
                };
                textSelection
                    .attr('x', x)
                    .attr('y', y)
                    .attr('opacity', showRelatedJobs ? 1 : 0);

                circleSelection.attr('cx', x).attr('cy', y);
                innerCircleSelection.attr('cx', x).attr('cy', y);
                linkSelection
                    .attr('x1', get(['source', 'x']))
                    .attr('y1', get(['source', 'y']))
                    .attr('x2', get(['target', 'x']))
                    .attr('y2', get(['target', 'y']))
                    .attr('id', get(['target', 'id']));
            });
        }
    }, [
        addFutureOccupationCode,
        data.links,
        data.nodes,
        occupation.id,
        showRelatedJobs,
        simulation,
    ]);

    return (
        <div css={{ position: 'relative' }}>
            <svg width={width} height={height} css={{ flexShrink: 0 }}>
                <g className="links" ref={linksRef} />

                <g className="nodes" ref={nodesRef} />
                <g>
                    <circle
                        stroke={colors.darkBlue}
                        cy={height / 2}
                        cx={width / 2}
                        r={middleCircleSize}
                        style={{ cursor: 'pointer' }}
                        fill="white"
                        onClick={() => {
                            setShowRelatedJobs(x => !x);
                        }}
                    />

                    <text
                        onClick={() => {
                            setShowRelatedJobs(x => !x);
                        }}
                        style={{ cursor: 'pointer' }}
                        fontSize="30"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        y={height / 2}
                        x={width / 2}
                        transform={`rotate(${
                            showRelatedJobs ? 0 : 45
                        }, ${width / 2},${height / 2})`}
                    >
                        &times;
                    </text>
                    <text>{occupation.label}</text>
                </g>
            </svg>
            {hoveredCircle && (
                <Popup
                    numberOfRelatedSkills={
                        getRelatedSkills(
                            occupation,
                            showingOccupationCodes,
                            prevOccupationsCodes,
                            hoveredCircle,
                            skillsByOccupationCode
                        ).length
                    }
                    hoveredCircle={hoveredCircle}
                    onMouseLeave={handleLeave(setHoveredCircle)}
                    top={
                        hoveredCircle.y +
                        occupationScale(hoveredCircle.similarityScore) +
                        outerStrokeSize * 2
                    }
                    left={hoveredCircle.x}
                />
            )}
        </div>
    );
}

export default connect(
    (state, props) => {
        const currentOccupation = get(
            'ANZSCO_MAPPED_CODE',
            state.ui.alternateTitleCurrentOccupation
        );
        const showingOccupationCodes = currentOccupation
            ? [currentOccupation].concat(state.ui.futureOccupations)
            : [];

        const skillsByOccupationCode = state.data.skillsByOccupationCode;
        const prevOccupationsCodes = state.ui.alternateTitlePastOccupations.map(
            x => x.ANZSCO_MAPPED_CODE
        );
        return {
            occupation: last(showingOccupationCodes),
            showingOccupationCodes,
            skillsByOccupationCode,
            prevOccupationsCodes,
            occupations: selectOccupationRelatedToOccupation(
                state,
                last(showingOccupationCodes)
            ),
        };
    },

    {
        addFutureOccupationCode,
    }
)(AddOccupationVis);

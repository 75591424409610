import { event } from 'd3-selection';

export const handleLeave = hidePopupFn => reactEvent => {
    const e = event || reactEvent;
    if (!e.toElement) {
        hidePopupFn();
        return;
    }
    const name = e.toElement.getAttribute('class');

    if (!name) {
        hidePopupFn();
        return;
    }

    if (name.includes('Popup') || name.includes('circle')) {
        return; // Don't set the hovered circle to null, hence popup will remain open
    }

    hidePopupFn();
};

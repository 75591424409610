import React from 'react';
import { colors, fontSizes } from '../modules/theme';

function Triangle({ backgroundColor, width, zIndex, marginTop }) {
    return (
        <div
            css={{
                width: 0,
                height: 0,
                zIndex: zIndex,
                marginTop: marginTop,
                borderLeft: `${width / 3}px solid transparent`,
                borderRight: `${width / 3}px solid transparent`,
                borderBottom: `${width / 2}px solid ${backgroundColor}`,
            }}
        />
    );
}

export default function ArrowUp(props) {
    const arrowColor = colors.lightBlue;
    return (
        <div
            css={{
                display: 'grid',
                justifyItems: 'center',
                paddingTop: 80,
                paddingBottom: 15,
            }}
            {...props}
        >
            <div
                css={{
                    display: 'grid',
                    justifyItems: 'center',
                    gridTemplateRows: '160px 42px 1fr 2px',
                    width: 50,
                }}
            >
                <div
                    css={{
                        display: 'grid',
                        justifyItems: 'center',
                        height: 70,
                        gridTemplateRows: '20px 1fr',
                    }}
                >
                    <div
                        css={{
                            display: 'grid',
                            justifyItems: 'center',
                        }}
                    >
                        <Triangle
                            backgroundColor={arrowColor}
                            marginTop={0}
                            zIndex={5}
                            width={21}
                        />
                        <Triangle
                            backgroundColor={colors.whiteBlue}
                            width={18}
                            marginTop={-12}
                            zIndex={10}
                        />
                    </div>
                    <div
                        css={{
                            backgroundColor: arrowColor,
                            width: 2,
                            height: 50,
                            zIndex: 20,
                            marginTop: -17,
                        }}
                    />
                </div>
                <div css={{ width: 'inherit' }}>
                    {' '}
                    <p
                        css={{
                            transform: 'rotate(-90deg)',
                            textTransform: 'uppercase',
                            color: arrowColor,
                            margin: '0 0 0 0',
                            fontSize: fontSizes.extraSmall,
                            whiteSpace: 'nowrap',
                        }}
                    >
                        Requires more time
                    </p>
                </div>

                <div
                    css={{
                        backgroundColor: arrowColor,
                        width: 2,
                        height: 'auto',
                    }}
                />
                <div
                    css={{ backgroundColor: arrowColor, width: 10, height: 2 }}
                />
            </div>
        </div>
    );
}

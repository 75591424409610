import React from 'react';
import { scale } from '../../modules/skill-circle';
import { BlueHeading } from '../../component/ui';
import ArrowUp from '../../component/ArrowUp';
import { colors } from '../../modules/theme';
import { connect } from 'react-redux';
import {
    flatMap,
    groupBy,
    maxBy,
    flow,
    filter,
    map,
    keyBy,
    orderBy,
    concat,
} from 'lodash/fp';

import { extent } from 'd3-array';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faRedo } from '@fortawesome/pro-solid-svg-icons';
import { addAddedSkill, addRemovedSkill, resetSkills } from '../../ducks/ui';
import TechnicalSkillsList from '../../component/technical-skills-list';

function TechnicalSkills({
    transferableSkills,
    currentOccupation,
    occupation,
    missingSkills,
    addAddedSkill,
    addRemovedSkill,
    resetSkills,
}) {
    scale.domain(
        extent(transferableSkills.concat(missingSkills), x => x.hoursPerWeek)
    );
    return (
        <div
            css={{
                background: colors.whiteBlue,
                padding: '10px 30px',
                marginLeft: '30px',
            }}
        >
            <div
                css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <h3>Technical tasks</h3>
                <div
                    css={{
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        resetSkills();
                    }}
                >
                    <FontAwesomeIcon icon={faRedo} />
                    <span css={{ marginLeft: 5, textDecoration: 'underline' }}>
                        Revert to default
                    </span>
                </div>
            </div>
            <p>
                The role of a {occupation.label} has technical tasks that
                describe the skills required for this job. Technical skills
                acquired from your current or previous experience are used to
                determine if you have transferrable skills or a skills gap. You
                can add or remove tasks from either the transferable skills or
                skills gap lists. Removing a task from one list will add it to
                the other list.
            </p>
            <div
                css={{
                    display: 'grid',
                    gridTemplateColumns: '45% 10% 45%',
                }}
            >
                <div>
                    <BlueHeading>
                        Transferable skills
                        <span>{transferableSkills.length}</span>
                    </BlueHeading>
                    <TechnicalSkillsList
                        onClick={skill => {
                            addRemovedSkill({
                                ...skill,
                                id:
                                    skill['DWA_TITLE'] +
                                    currentOccupation.ANZSCO_MAPPED_CODE,
                                ANZSCO_MAPPED_CODE:
                                    currentOccupation.ANZSCO_MAPPED_CODE,
                                ANZSCO_MAPPED_TITLE:
                                    currentOccupation.ANZSCO_MAPPED_TITLE,
                            });
                        }}
                        icon={faTimes}
                        scale={scale}
                        skills={transferableSkills}
                        skillType="transferable"
                    />
                </div>
                <ArrowUp />
                <div>
                    <BlueHeading>
                        Skills gap <span>{missingSkills.length}</span>
                    </BlueHeading>
                    <TechnicalSkillsList
                        onClick={skill => {
                            addAddedSkill({
                                ...skill,
                                id:
                                    skill['DWA_TITLE'] +
                                    currentOccupation.ANZSCO_MAPPED_CODE,
                                ANZSCO_MAPPED_CODE:
                                    currentOccupation.ANZSCO_MAPPED_CODE,
                                ANZSCO_MAPPED_TITLE:
                                    currentOccupation.ANZSCO_MAPPED_TITLE,
                            });
                        }}
                        icon={faPlus}
                        scale={scale}
                        skills={missingSkills}
                        skillType="missing"
                    />
                </div>
            </div>
        </div>
    );
}

export default connect(
    (state, props) => {
        const occupationsByCode = state.data.occupationsByCode;

        const skillsByOccupationCode = state.data.skillsByOccupationCode;
        const { ANZSCO_MAPPED_CODE } =
            state.ui.alternateTitleCurrentOccupation || {};
        const previousOccupations =
            state.ui.alternateTitlePastOccupations || [];
        const { addedSkills, removedSkills } = state.ui;
        const removedSkillsByLabel = keyBy('label', removedSkills);

        const codes = [ANZSCO_MAPPED_CODE].concat(
            previousOccupations.map(x => x.ANZSCO_MAPPED_CODE)
        );
        //This logic could go into a selector
        const existingSkillsByLabel = flow(
            filter(Boolean),
            flatMap(code => skillsByOccupationCode[code]),
            concat(addedSkills),
            filter(skill => !removedSkillsByLabel[skill.label]),
            groupBy('label'),
            Object.values,
            map(skills => {
                const currentSkill = skills.find(
                    skill => skill.id === ANZSCO_MAPPED_CODE
                );
                // If we have a current skill use that, otherwise grab the skill with the most hours/week
                if (currentSkill) {
                    return currentSkill;
                }
                return maxBy('hoursPerWeek', skills);
            }),
            keyBy('label')
        )(codes);

        const skillsForPickedOccupation = orderBy(
            'hoursPerWeek',
            'desc',
            skillsByOccupationCode[props.match.params.occupationCode]
        );

        const {
            transferableSkills,
            missingSkills,
        } = skillsForPickedOccupation.reduce(
            (acc, skill) => {
                if (existingSkillsByLabel[skill.label]) {
                    acc.transferableSkills.push(skill);
                } else {
                    acc.missingSkills.push(skill);
                }
                return acc;
            },
            { transferableSkills: [], missingSkills: [] }
        );
        return {
            occupation: occupationsByCode[props.match.params.occupationCode],
            currentOccupation: state.ui.alternateTitleCurrentOccupation,
            transferableSkills,
            missingSkills,
        };
    },
    {
        addAddedSkill,
        addRemovedSkill,
        resetSkills,
    }
)(TechnicalSkills);

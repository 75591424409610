import React, { useEffect } from 'react';
import OccupationSelect from '../component/occupation-select';

const occupationWorker = new Worker('../modules/worker-filter-options.js', {
    type: 'module',
});

const alternateTitleToOption = x =>
    x
        ? {
              value: x,
              label: `${x.ANZSCO_MAPPED_TITLE} (${x.ALTERNATE_TITLES})`,
          }
        : null;

export default function Occupations({ history }) {
    useEffect(() => {
        fetch('/data/alternate-titles.json')
            .then(x => x.json())
            .then(data => {
                const options = data.map(alternateTitleToOption);
                occupationWorker.postMessage({
                    type: 'STORE_OPTIONS',
                    payload: options,
                });
            });
    }, []);
    return (
        <div css={{ width: 400 }}>
            <p>I'm interested in being a</p>
            <OccupationSelect
                worker={occupationWorker}
                name={'current'}
                isClearable={false}
                onChange={val => {
                    history.push(
                        '/occupations/' + val.value.ANZSCO_MAPPED_CODE
                    );
                }}
                placeholder="Select occupation"
                noOptionsMessage={({ inputValue }) => {
                    if (inputValue.length < 3) {
                        return 'Type at least 3 characters to search';
                    }
                    return 'No Options';
                }}
            />
        </div>
    );
}

import React from 'react';
import { colors, fontSizes } from '../modules/theme';

function PositiveGrowthToggle(props) {
    return (
        <div css={{ color: colors.lightBlue, fontSize: fontSizes.small }}>
            <label>
                <input
                    value={props.onlyShowPositiveGrowth}
                    type="checkbox"
                    onChange={() => {
                        props.onChange(!props.onlyShowPositiveGrowth);
                    }}
                />
                Only show jobs with positive growth
            </label>
        </div>
    );
}

export default PositiveGrowthToggle;

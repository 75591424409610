import { uniqBy, filter, flatMap, map, flow, keyBy, concat } from 'lodash/fp';
import { scaleLinear } from 'd3-scale';

export const isMissingSkill = (showingOccupationCodes, skill) => {
    if (isTransferableSkill(showingOccupationCodes, skill)) {
        return false;
    }
    const belongsToFutureOccupation = skill.forOccupations.some(code =>
        showingOccupationCodes.slice(1).includes(code)
    );
    return belongsToFutureOccupation;
};
export const isTransferableSkill = (showingOccupationCodes, skill) => {
    const belongsToFutureOccupation = skill.forOccupations.some(code =>
        showingOccupationCodes.slice(1).includes(code)
    );
    return skill.forOccupations.length > 1 && belongsToFutureOccupation;
};

const occupationLabelWidth = 200;
export const xPosForOccupation = (width, showingOccupationCodes, index) => {
    const paddingRight = index * 10;
    return occupationLabelWidth * (index + 1) + paddingRight;
};
export const getData = (
    width,
    height,
    skillsByOccupationCode,
    skillsByName,
    occupationsByCode,
    showingOccupationCodes,
    addedSkills,
    removedSkills,
    prevOccupationsCodes
) => {
    const randomYPosScale = scaleLinear()
        .range([0, height])
        .domain([0, 1]);
    const randomXPosScale = scaleLinear()
        .range([0, width])
        .domain([0, 1]);
    const removedSkillsByLabel = keyBy('label', removedSkills);

    var occupations = showingOccupationCodes.map((code, i) => {
        const occupation = occupationsByCode[code];
        return {
            ...occupation,
            fx: xPosForOccupation(width, showingOccupationCodes, i),
            fy: 240,
        };
    });
    const addedSkillsByLabel = keyBy('label', addedSkills);
    const skillsFromPrevOccupations = flatMap(
        code => skillsByOccupationCode[code],
        prevOccupationsCodes
    );
    const skillsForSelectedOccupations = flow(
        flatMap(code => skillsByOccupationCode[code]),
        concat(skillsFromPrevOccupations),
        uniqBy('label'),
        map(skill => {
            const addCurrentJob = addedSkillsByLabel[skill.label];
            const removeCurrentJob = removedSkillsByLabel[skill.label];
            const forOccupations = flow(
                map('ANZSCO_MAPPED_CODE'),
                filter(d =>
                    prevOccupationsCodes
                        .concat(showingOccupationCodes)
                        .includes(d)
                ),
                codes =>
                    addCurrentJob
                        ? codes.concat(addCurrentJob.ANZSCO_MAPPED_CODE)
                        : codes,
                codes =>
                    removeCurrentJob
                        ? codes.filter(
                              c => c !== removeCurrentJob.ANZSCO_MAPPED_CODE
                          )
                        : codes
            )(skillsByName[skill.DWA_TITLE]);

            return {
                ...skill,
                x: randomXPosScale(Math.random()),
                y: randomYPosScale(Math.random()),
                forOccupations,
            };
        })
    )(showingOccupationCodes);
    const nodes = occupations.concat(skillsForSelectedOccupations);

    const links = flatMap(occupation => {
        const occupationSkills = skillsForSelectedOccupations.filter(skill =>
            skill.forOccupations.includes(occupation.ANZSCO_MAPPED_CODE)
        );
        return occupationSkills.map(skill => ({
            source: occupation,
            target: skill,
        }));
    }, occupations);

    return {
        nodes,
        links,
    };
};
